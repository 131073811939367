import React,{useContext, useEffect, useState} from 'react'
import styles from "./UpdateQr.module.css"
import {useNavigate} from "react-router-dom"
import {update_qr} from "../../../Validations/HomeValidations" 
import Loader from '../../Utilities/Loader'
import {IsQrCode} from "../../../App"
import { toast } from 'react-toastify';
import { collection,where,query, getDocs,addDoc,doc, updateDoc } from 'firebase/firestore'
import { db } from '../../../firebase/config'

function UpdateQr() {

  const colRef = collection(db,"qr_code_details")
  const isQrCode = useContext(IsQrCode)
  const [isLoading,setIsLoading] = useState(false)
  const [discountPercent,setDiscountPercent] = useState("")
  const [errorMsg,setErrorMsg] = useState([]) 
  const [discount,setDiscount] = useState(-1);
  const [expire,setExpire] = useState("")
  const [e,setE] = useState(false)
  const navigate = useNavigate()
  const [date,setDate] = useState(null)
  const [myData,setMyDAta] = useState({})

  const discountHandle = (index,value) =>{
      setDiscount(index)
      if(value === "1 Week"){
        let date = new Date()
        date.setDate(new Date().getDate() + 7)
        setExpire(date.toISOString().replace(/T.*/,''))
    }else if(value === "2 Week"){
        let date = new Date()
        date.setDate(new Date().getDate() + 14)
        setExpire(date.toISOString().replace(/T.*/,''))
    }else if(value === "1 Month"){
        let date = new Date()
        date.setDate(new Date().getDate() + 30)
        
        setExpire(date.toISOString().replace(/T.*/,''))
    }else{
        let date = new Date()
        date.setDate(new Date().getDate() + 9999)
        
        setExpire(date.toISOString().replace(/T.*/,''))
    }
  }

  const discountPercentHandler = (e) =>{
    setDiscountPercent(e.target.value)
  }

  const merchant_id = sessionStorage.getItem("merchantId")
  const merchant_name = sessionStorage.getItem("storeName")

  const generateQrHandler = (e) =>{
    e.preventDefault()

    expire === "" ? setE(true) : setE(false)

    const data = {
      dis_percent:discountPercent,
    }

    update_qr.validate(data,{abortEarly:false})
    .then(async (response) =>{
        if(expire === ""){
          console.log("wrong")
        }else{
          setIsLoading(true)
          try{
            const generate_qr_code = await addDoc(colRef,{
              BelongsTo:merchant_name,
              MerchantId:merchant_id,
              isQrCodeGenerated:true,
              Expire:expire,
              Value:parseInt(discountPercent)
            })

            if(generate_qr_code.id){
              isQrCode.setIsQrCode(true)
              setIsLoading(false)
              setDiscountPercent("")
              setDiscount(-1)
              toast.success("Qr Code Generated Successfully")
              fetchData()
            }else{
              setDiscountPercent("")
              setDiscount(-1)
              toast.success("Problem in Generating Qr Code")
              setIsLoading(false)
            }
          }catch(err){
            console.log(err);
            setIsLoading(false)
          }
        }
      setErrorMsg([])
    }).catch((error) =>{
      setErrorMsg(error.errors)
    })
  }

  const preview = () =>{
    navigate("/qr_code")
  }


  const fetchData = async () =>{
    try{
      const  get_qr_code = query(colRef,where('MerchantId',"==",merchant_id))
      const doc = await getDocs(get_qr_code)
      if(doc.size > 0){
          let qrData;
          doc.forEach((doc) => {
              qrData = doc.data()
              qrData.id = doc.id
          });
          const date1 = new Date();
          const d2 = new Date(qrData.Expire + ' 23:59:59')
          if(date1 < d2 ){
            const date2 = new Date(qrData.Expire);
            const Difference_In_Time = date2.getTime() - date1.getTime();
            let Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24) + 1;
            Difference_In_Days = Math.floor(Difference_In_Days) + "d"
            setDate(Difference_In_Days)
          }
          setMyDAta(qrData)
          setIsLoading(false)
      }else{
          isQrCode.setIsQrCode(false) 
      }
    }catch(err){
      console.log(err);
    }
  }

  useEffect(() =>{
    fetchData()
  },[])

  const updateQrHandler = () =>{
    expire === "" ? setE(true) : setE(false)
        const data = {
            dis_percent:discountPercent,
        }
        update_qr.validate(data,{abortEarly:false})
        .then(async (response) =>{  

            if(expire === ""){
                console.log("wrong")
            }else{
                setIsLoading(true)
                try{
                    const update_qr_code = doc(db,"qr_code_details",myData.id)
                    await updateDoc(update_qr_code,{
                      Value : discountPercent,
                      Expire : expire
                    })
                    setDiscountPercent("")
                    setDiscount(-1)
                    toast.success("Qr Code updated successfully.")
                    fetchData()
                }catch(err){
                  console.log(err);
                  setIsLoading(false)
                }
            }  
            setErrorMsg([])
        }).catch((error) =>{
          setErrorMsg(error.errors)
        })
  }

  return (
    <>   
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.msg}>
            {
              isQrCode.isQrCode ?
              <h1>Update QR Code</h1>
              : <h1>Generate QR Code</h1>
            }
          </div>
          {
            isQrCode.isQrCode &&
            <div className={styles.values}>
                  <p> Value : {myData.Value ? myData.Value : 0} </p>
                  <p>{date === null ? `Expiry : 0` : date ? `Expiry : ${date}` : 'Expired'}</p>
              </div>
          }
          <div className={styles.inputType}>
            <div>
                <input type="text" required={true} value={discountPercent} onChange={discountPercentHandler} />
                {
                   isQrCode.isQrCode ?
                    <label>New Discount Percentage</label>
                    : <label>Discount Percentage</label>
                }
            </div>
                {
                  errorMsg.map((item,index) =>(
                      <div style={{
                          color:"red",
                          fontWeight:"600",
                          fontSize:"14px",
                          marginTop:"3px"
                      }} key={index}>{item.per}</div>
                  ))
                }
          </div>
          <div className={styles.exp}>
                <p>Expire After</p>
                <div className={styles.expire}>
                    <button onClick={() => discountHandle(0,"1 Week")} className={discount === 0 ? styles.active : undefined} >1 Week</button>
                    <button onClick={() => discountHandle(1,"2 Week")} className={discount === 1 ? styles.active : undefined} >2 Week</button>
                    <button onClick={() => discountHandle(2,"1 Month")} className={discount === 2 ? styles.active : undefined} >1 Month</button>
                    <button onClick={() => discountHandle(3,"Never")} className={discount === 3 ? styles.active : undefined} > Never </button>
                </div>
                {
                  e &&
                      <div style={{
                          color:"red",
                          fontWeight:"600",
                          fontSize:"14px",
                          marginTop:"3px"
                      }}>**Please select one option </div>
                
                  }
            </div>
            <div className={styles.generate}>
              <div>
                {
                  isQrCode.isQrCode ? 
                    <button className={styles.checkbtn} onClick={updateQrHandler}> Update </button> :
                    <button className={styles.checkbtn} onClick={generateQrHandler}> Generate </button>
                }
                {
                  isQrCode.isQrCode &&
                  <button className={styles.checkbtn} onClick={preview}> Preview </button>
                }
              </div>
            </div>
        </div>
      </div>
      {
        isLoading && <Loader />
      }
    </>
  )
}

export default UpdateQr
