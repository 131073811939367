export const SECRET_KEY = "5B609d26XCVK3bf1c234cedK9J9203d777c3"
export const BASE_URL = "https://apis.foodflyer.ca/food_api/"
// export const BASE_URL = "http://localhost:3000/food_api/"
export const QR_CODE = "https://chart.googleapis.com/chart?cht=qr&chs=500x500&chl="

// export const SERVER_URL = "http://localhost:3000" 
export const SERVER_URL = "https://www.scriptbox.ca" 

export const PROMO_CODE_EMAIL_TEMPLATE = '2d6f.3d258bbbb0012565.k1.61292620-c8d5-11ee-9f9b-52540064429e.18d980bd182'
export const VERIFY_EMAIL_TEMPLATE = '2d6f.3d258bbbb0012565.k1.6f198a60-c8d8-11ee-9f9b-52540064429e.18d981fd606'
export const RESET_PASSWORD_EMAIL_TEMPLATE = '2d6f.3d258bbbb0012565.k1.3d5d0050-c8f2-11ee-9f9b-52540064429e.18d98c8f4d5'
