import React, { useEffect, useState } from 'react'
import styles from "./SocialMedia.module.css"
import Loader from "../../Utilities/Loader"
import { socialMedia } from '../../../Validations/HomeValidations'
import { toast } from 'react-toastify';
import { db } from '../../../firebase/config'
import { doc,updateDoc, getDoc } from 'firebase/firestore'


function SocialMedia() {
  const merchant_id = sessionStorage.getItem("merchantId")
  const [allData,setAllData] = useState({
    tiktok:"",
    instagram:"",
    facebook:"",
    twitter:""
  })

  const [errorMsg,setErrorMsg] = useState([]) 
  const [isLoading,setIsLoading] = useState(false)

  const getDetails = async () =>{
    try{
      const docRef = doc(db,"merchants",merchant_id)
      const docs = await getDoc(docRef)
      if(docs.exists()){
        setAllData({
          tiktok:docs.data().Tiktok,
          instagram:docs.data().Instagram,
          facebook:docs.data().Facebook,
          twitter:docs.data().Twitter
        })
      }
    }catch(err) {
      console.log(err);
    }
  }

  useEffect(() =>{
    getDetails()
  },[])

  const instagramHandler = (e) =>{
    setAllData({
      ...allData,
      instagram:e.target.value
    })
  }
  const facebookHandler = (e) =>{
    setAllData({
      ...allData,
      facebook:e.target.value
    })
  }
  const twitterHandler = (e) =>{
    setAllData({
      ...allData,
      twitter:e.target.value
    })
  }
  const tiktokHandler = (e) =>{
    setAllData({
      ...allData,
      tiktok:e.target.value
    })
  }

  const updateHandler = (e) =>{
    e.preventDefault()

    const data = {
      ...allData
    }

    socialMedia.validate(data,{abortEarly:false})
    .then(async (response) =>{
      setIsLoading(true)
      try{
        const updateDocRef = doc(db,"merchants",merchant_id)
        await updateDoc(updateDocRef,{
          Tiktok : allData.tiktok,
          Instagram : allData.instagram,
          Facebook : allData.facebook,
          Twitter : allData.twitter
        })
     
          setAllData({
            tiktok:"",
            instagram:"",
            facebook:"",
            twitter:""
          })
          getDetails()
          toast.success("Social Media Updated Successfully")
          setIsLoading(false)
      
      }catch(err){
        console.log(err);
        setIsLoading(false)
      }
      setErrorMsg([])
    }).catch((error) =>{
      setErrorMsg(error.errors)
    })

  }
  return (
    <> 
      <div className={styles.tab}>
          <div className={styles.formPart}>
              <div className={styles.inputType}>
                <div>
                  <input type="text" required={true} value={allData.facebook} onChange={facebookHandler} />
                  <label>Facebook Username</label>
                </div>
                {
                  errorMsg.map((item,index) =>(
                      <div style={{
                          color:"red",
                          fontWeight:"600",
                          fontSize:"14px",
                          marginTop:"3px"
                      }} key={index}>{item.facebook}</div>
                  ))
                }
              </div>
              <div className={styles.inputType}>
                <div>
                  <input type="text" required={true} value={allData.instagram} onChange={instagramHandler} />
                  <label>Instagram Username</label>
                </div>
                {
                  errorMsg.map((item,index) =>(
                      <div style={{
                          color:"red",
                          fontWeight:"600",
                          fontSize:"14px",
                          marginTop:"3px"
                      }} key={index}>{item.instagram}</div>
                  ))
                }
              </div>
              <div className={styles.inputType}>
                <div>
                  <input type="text" required={true} value={allData.twitter} onChange={twitterHandler} />
                  <label>Twitter Username</label>
                </div>
                {
                  errorMsg.map((item,index) =>(
                      <div style={{
                          color:"red",
                          fontWeight:"600",
                          fontSize:"14px",
                          marginTop:"3px"
                      }} key={index}>{item.twitter}</div>
                  ))
                }
              </div>
              <div className={styles.inputType}>
                <div>
                  <input type="text" required={true} value={allData.tiktok} onChange={tiktokHandler} />
                  <label>Tiktok Username</label>
                </div>
                {
                  errorMsg.map((item,index) =>(
                      <div style={{
                          color:"red",
                          fontWeight:"600",
                          fontSize:"14px",
                          marginTop:"3px"
                      }} key={index}>{item.tiktok}</div>
                  ))
                }
              </div>
              <div className={styles.checkout}>
                <button onClick={updateHandler} className={styles.checkbtn}> Update </button>
            </div>
          </div>
      </div>
      {
          isLoading && <Loader />
            
        }
    </>
  )
}

export default SocialMedia