import React, { useEffect,useState,useRef } from 'react'
import style from "./QrCode.module.css"
import {QR_CODE, SERVER_URL} from "../../Constants/index"
import axios from "axios"
import {Buffer} from 'buffer';
import {useNavigate} from "react-router-dom"
import Loader from '../Utilities/Loader';
import { Base64 } from '../../EncodeDecode/index';
import { useReactToPrint } from "react-to-print";
import { collection,where,query, getDocs } from 'firebase/firestore'
import { db } from '../../firebase/config';

function QrCode() {
    const colRef = collection(db,"qr_code_details")
    const [isLoading,setIsLoading] = useState(false)
    const [qr_data,setQr_Data] = useState()
    const [myData,setMyData] = useState({})
    const componentRef = useRef();

    const navigate = useNavigate()
    
    const generateQrCode = (data) =>{
        const url = `${SERVER_URL}/qr_scan_form?info=${Base64.encode(JSON.stringify(data))}`
        const qr_url = QR_CODE + url
        axios.get(qr_url,{responseType:'arraybuffer'})
        .then((response) =>{
          let base64ImageString = Buffer.from(response.data, 'binary').toString('base64')
          let srcValue = "data:image/png;base64,"+base64ImageString
          setQr_Data(srcValue)
          setIsLoading(false)
        })
      }
    
  const merchant_id = sessionStorage.getItem("merchantId")

  const fetchData = async () =>{
    try{
      const  get_qr_code = query(colRef,where('MerchantId',"==",merchant_id))
      const doc = await getDocs(get_qr_code)
      if(doc.size){
          let qrData;
          doc.forEach((doc) => {
              qrData = doc.data()
          });
          setMyData(qrData)
          generateQrCode({Merchant : qrData.MerchantId})
      }else{
        setIsLoading(false)
      }
    }catch(err){
      console.log(err);
      setIsLoading(false)
    }
  }

    useEffect(() =>{
      setIsLoading(true)
      fetchData()
    },[])  

    const backHandler = () =>{
        navigate("/home?tab=2")
    }

    const handlePrint = useReactToPrint({
      content: () => componentRef.current, 
    });
  return (
    <>
        <div className={style.containerQr}>
            <div ref={componentRef}  className={style.wrapperQr}>
                <div className={style.qrImg}>
                  <div className={style.left}>
                    <h1>foodflyer</h1>
                  </div>
                </div>
                <div className={style.QrHold}>
                    <div className={style.contentQr}>
                        <div className={style.title}>
                            <h2>{myData.BelongsTo}</h2>
                        </div>
                        <div className={style.qrcode}>
                            {qr_data ? <img src={qr_data} alt="QR Code" /> : <h1 className={style.name}>Wait Generating...</h1>}
                        </div>
                        <div className={style.nextorder}>
                            <p> Get Upto 20% Off</p>
                            <p> Scan Code To Continue</p> 
                        </div>
                    </div>
                </div>
            </div>
            <div className={style.button}>
                <button onClick={handlePrint}> Print </button>
                <button onClick={backHandler}> Back </button>
            </div>
        </div>
        {
          isLoading && <Loader />
        }
    </>
    )
}

export default QrCode