import React,{useState} from 'react'
import styles from "./Password.module.css"
import Loader from '../../Utilities/Loader'
import {BASE_URL,SECRET_KEY} from "../../../Constants/index"
import axios from 'axios'
import {updatePassword} from "../../../Validations/Authentication"
import { toast } from 'react-toastify';

function Password() {

  const [allData,setAllData] = useState({
    old_password:"",
    new_password:"",
    confirm_password:""
  })

  const [errorMsg,setErrorMsg] = useState([]) 
  const [isLoading,setIsLoading] = useState(false)

  const confHandler = (e) =>{
    setAllData({
      ...allData,
      confirm_password:e.target.value
    })
  }
  const newHandler = (e) =>{
    setAllData({
      ...allData,
      new_password:e.target.value
    })
  }
  const oldHandler = (e) =>{
    setAllData({
      ...allData,
      old_password:e.target.value
    })
  }

  const update_Password = (e) =>{
    e.preventDefault()
    const data = {
      ...allData
    }
    console.log(data)
    const url = BASE_URL + "update_password"
    const config = {
      headers:{
        "secret_key" : SECRET_KEY,
        "Content-Type" : "application/json"
      }
    }
    updatePassword.validate(data,{abortEarly:false})
    .then((response) =>{
      if(allData.new_password === allData.confirm_password){
        setIsLoading(true)
        const resData = {
          id:sessionStorage.getItem("merchantId"),
          old_password:allData.old_password,
          new_password:allData.new_password
        }
        axios.post(url,resData,config)
        .then((response) =>{
          console.log(response)
          const result = JSON.parse(response.data)
          if(result.code === 1){
            setAllData({
              old_password:"",
              new_password:"",
              confirm_password:""
            })
            toast.success("Password Updated Successfully")
            setIsLoading(false)
          }else{
            toast.error("Problem in updating password")
            setIsLoading(false)
          }
        }).catch((error) =>{
          console.log(error)
          setIsLoading(false)
        })
      }else{
        alert("new password not match with confirm password")
      }
      setErrorMsg([])
    }).catch((error) =>{
      setErrorMsg(error.errors)
    })
  }
  return (
    <>
      <div className={styles.tab}>
          <div className={styles.formPart}>
              <div className={styles.inputType}>
                <div>
                  <input type="text" required={true} value={allData.old_password} onChange={oldHandler} />
                  <label>Old Password</label>
                </div>
                {
                  errorMsg.map((item,index) =>(
                      <div style={{
                          color:"red",
                          fontWeight:"600",
                          fontSize:"14px",
                          marginTop:"3px"
                      }} key={index}>{item.old}</div>
                  ))
                }
              </div>
              <div className={styles.inputType}>
                <div>
                  <input type="text" required={true} value={allData.new_password} onChange={newHandler} />
                  <label>New Password</label>
                </div>
                {
                  errorMsg.map((item,index) =>(
                      <div style={{
                          color:"red",
                          fontWeight:"600",
                          fontSize:"14px",
                          marginTop:"3px"
                      }} key={index}>{item.new}</div>
                  ))
                }
              </div>
              <div className={styles.inputType}>
                <div>
                  <input type="text" required={true} value={allData.confirm_password} onChange={confHandler} />
                  <label>Confirm Password</label>
                </div>
                {
                  errorMsg.map((item,index) =>(
                      <div style={{
                          color:"red",
                          fontWeight:"600",
                          fontSize:"14px",
                          marginTop:"3px"
                      }} key={index}>{item.conf}</div>
                  ))
                }
              </div>
              <div className={styles.checkout}>
                <button onClick={update_Password} className={styles.checkbtn}> Update Password </button>
            </div>
          </div>
      </div>
      {
          isLoading && <Loader />
            
        }
    </>
  )
}

export default Password