import axios from 'axios'
import React, { useEffect,useState } from 'react'
import { BASE_URL, SECRET_KEY } from '../../Constants'
import Footer from '../Partialpages/Footer'
import NavBar from '../Partialpages/NavBar'
import styles from "./CouponReports.module.css"
import Loader from '../Utilities/Loader'
import { Table } from 'antd';
import { db } from '../../firebase/config'
import { collection,where,query, getDocs } from 'firebase/firestore'
import { formatDate } from '../Utilities/CommonFunctions'

function CouponReports() {
  const colRef = collection(db,"promo_codes")
  const merchant_id = sessionStorage.getItem("merchantId")

  const [subscriber,setSubscriber] = useState([])
  const [isLoading,setIsLoading] = useState(false)

  const getAllCouponCodes = async () => {
    setIsLoading(true)
    try{
      const find_all_coupons = query(colRef,where('MerchantID', "==", merchant_id))
      const docs = await getDocs(find_all_coupons)
 
      if(docs.size > 0){
        const data = [];
        let count = 0
        docs.forEach((doc) => {
          count = count + 1
          let Name = `${doc.data().subscriberData.FirstName} ${doc.data().subscriberData.LastName}`
          let format_date = formatDate(new Date(doc.data().CreatedDate.seconds * 1000 + doc.data().CreatedDate.nanoseconds / 1e6));
          data.push({
            key: count,
            email: doc.data().subscriberData.EmailAddress,
            coupon_code : doc.data().CouponCode,
            value: doc.data().CouponValue,
            generate: format_date, 
            expire: doc.data().Expiry,
            type: doc.data().PromocodeType,
            subscriber: Name,
            claimed : doc.data().IsClaimed ? "YES" : "NO"
          });
        })
        setIsLoading(false)
        setSubscriber(data)
      }else{
        setIsLoading(false)
      }
    }catch(err){
      console.log(err);
      setIsLoading(false)
    }
  }

  useEffect(() =>{
    getAllCouponCodes()
  },[])


  const columns = [
    {
        title: "Sr No.",
        dataIndex: "key",
        align: "left",
      },
      {
        title: "Coupon Code",
        dataIndex: "coupon_code",
        align: "left",
      },
      {
        title: "Value",
        dataIndex: "value",
        align: "left",
      },
      {
        title: "Generate",
        dataIndex: "generate",
        align: "left",
      },
      {
        title: "Expire",
        dataIndex: "expire",
        align: "left",
      },
      {
        title: "Claimed",
        dataIndex: "claimed",
        align: "left",
      },
      {
        title: "Type",
        dataIndex: "type",
        align: "left",
      },
      {
        title: "Subscriber",
        dataIndex: "subscriber",
        align: "left",
      },
      {
        title: "Email",
        dataIndex: "email",
        align: "left",
      },
  ];

  return (
    <>
       <NavBar />
        <div className={styles.container}>
        <div className={styles.wrapper}>
            <div className={styles.heading}>
              <h1>Promocodes Report</h1>
            </div>
            <div className={styles.content}>
              <div className={styles.tableWrapper}>

              
                    <div
                        style={{ overflowX: "auto" }}
                        >
                        <Table
                            columns={columns}
                            dataSource={subscriber}
                            pagination={{
                            pageSizeOptions: [
                                "5",
                                "10",
                                "30",
                                "60",
                                "100",
                            ],
                            showSizeChanger: true,
                            }}
                            scroll={{x:true}}
                            style={{ whiteSpace: "pre" }}
                            exportFileName="reports"
                        />
                        </div>
              </div>
            </div>
          </div>
        </div>
      <Footer />
      {
          isLoading && <Loader />
        }
    </>
  )
}

export default CouponReports