export const random_string = (length) =>{
    var result  = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * 
    charactersLength));
    }
    return result;
}


export const formatDateTime = (datetime) => {
  var dd = datetime.getDate();
  var mm = datetime.getMonth() + 1;
  var yyyy = datetime.getFullYear();
  var hr = datetime.getHours();
  var min = datetime.getMinutes();
  var sec = datetime.getSeconds();
  if (dd < 10) { dd = '0' + dd }
  if (mm < 10) { mm = '0' + mm }
  if (hr < 10) { hr = '0' + hr }
  if (min < 10) { min = '0' + min }
  if (sec < 10) { sec = '0' + sec }
  datetime = yyyy + '-' + mm + '-' + dd + ' ' + hr + ':' + min + ':' + sec;
  return datetime
}


export const formatDate = (date) => {
  var dd = date.getDate();
  var mm = date.getMonth() + 1;
  var yyyy = date.getFullYear();
  if (dd < 10) { dd = '0' + dd }
  if (mm < 10) { mm = '0' + mm }
  date = yyyy + '-' + mm + '-' + dd;
  return date
}

export const todays_date = () => {
  var dateIST = new Date();
  var month = dateIST.getMonth() + 1;
  var date = dateIST.getDate();
  if (date < 10) {
      date = '0' + date;
  }
  if (month < 10) {
      var month = '0' + month;
  }
  return dateIST.getFullYear() + '-' + month + '-' + date;
}