import React,{useEffect, useState} from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { scanForm } from '../../Validations/HomeValidations'
import CustomerNav from '../Partialpages/CustomerNav'
import Footer from '../Partialpages/Footer'
import styles from "./QrScanForm.module.css"
import {SERVER_URL,PROMO_CODE_EMAIL_TEMPLATE} from "../../Constants/index"
import axios from 'axios'
import Loader from '../Utilities/Loader'
import {Base64} from "../../EncodeDecode/index"
import { toast } from 'react-toastify';
import { db } from '../../firebase/config'
import { collection,where,query, getDocs,addDoc } from 'firebase/firestore'
import { random_string } from '../Utilities/CommonFunctions'

function QrScanForm() {

    const qrRef = collection(db,"qr_code_details")
    const colRef = collection(db,"subscribers")
    const merchantSubscriberColRef = collection(db,"merchant_subscriber_relations")
    const promoCodesColRef = collection(db,"promo_codes")
    const [myData,setMyData] = useState()
    const [isLoading,setIsLoading] = useState(false)
    const [params] = useSearchParams()
    const [errorMsg,setErrorMsg] = useState([]) 
    const navigate = useNavigate()
    const location = useLocation()
    const [allData,setAllData] = useState({
        email:"",
        f_Name:"",
        l_Name:"",
        phoneNumber:"",
    })
    const [disabled,setDisabled] = useState(false)
    const info = JSON.parse(Base64.decode(params.get("info")))

    const getQrCodeInfo = async () => {
        try{
            const get_qr_code = query(qrRef,where('MerchantId','==', info.Merchant ))
            const qr = await getDocs(get_qr_code)
            if(qr.size){
                let qrData;
                qr.forEach(doc => {
                    qrData = doc.data()
                    qrData.id = doc.id
                })
                const d1 = new Date()
                const d2 = new Date(qrData.Expire + ' 23:59:59')
                if(d1 > d2 ){
                    setDisabled(true)
                    toast.error('Scanned qr code is expire.')
                    navigate('/expire_qr_code')
                }else{
                    setDisabled(false)
                    setMyData(qrData)
                }
            }
        }catch(err){
            console.log(err);
        }
    }


    useEffect(() =>{
        getQrCodeInfo()
    },[])

    const emailHandler = (e) =>{
        setAllData({
            ...allData,
            email:e.target.value
        })
  }

    const f_nameHandler = (e) =>{
        setAllData({
            ...allData,
            f_Name:e.target.value
        })
    }
    const l_nameHandler = (e) =>{
        setAllData({
            ...allData,
            l_Name:e.target.value
        })
    }
    const phoneHandler = (e) =>{
        setAllData({
            ...allData,
            phoneNumber:e.target.value
        })
    }


    const addCustomerMerchantRelation = async (data) => {
        const is_relation_exist = query(merchantSubscriberColRef,where('MerchantId','==',info.Merchant),where('SubscriberId','==',data.subscriber_id))
        const docs = await getDocs(is_relation_exist)
        if(docs.size === 0){
            const add_relation = await addDoc(merchantSubscriberColRef,{
                MerchantId : info.Merchant,
                SubscriberId : data.subscriber_id,
                relationCreatedDate : new Date(),
                Active:true,
                subscriberData: {
                    EmailAddress:allData.email,
                    Phone:allData.phoneNumber,
                    FirstName:allData.f_Name,
                    LastName:allData.l_Name,
                    IsActive:true,
                }
            })
            if(add_relation.id){
                generatePromoCode({customer_id : data.subscriber_id})
            }else{
                setIsLoading(false)
                toast.error('Something went wrong while creating relations.')
            }
        }else{
            setIsLoading(false)
            toast.error('Customer already registered with the given email address.')
        }
      }
      
      const generatePromoCode = async (data) => {
        const random_code = random_string(6).toUpperCase()
        const generate_promo_code = await addDoc(promoCodesColRef,{
            MerchantID : info.Merchant,
            SubscriberId : data.customer_id,
            CouponCode : random_code,
            IsClaimed: false,
            CreatedDate: new Date(),
            ClaimedDate: null,
            CouponValue : myData.Value,
            Expiry : myData.Expire,
            PromocodeType : "percentage",
            subscriberData: {
                EmailAddress:allData.email,
                Phone:allData.phoneNumber,
                FirstName:allData.f_Name,
                LastName:allData.l_Name,
                IsActive:true,
            }
        })
        if(generate_promo_code.id){

            let config = {
                method: "post",
                maxBodyLength: Infinity,
                url: `https://tifinhub-api-hacsigy3hq-uc.a.run.app/foodflyer_email_trigger/send_email`,
                headers: {
                  "Content-Type": "application/json",
                },
                data: {
                  senderEmail : allData.email,
                  subject : 'Promo Code',
                  templateKey : PROMO_CODE_EMAIL_TEMPLATE,
                  variables : {
                    brand : "Scriptbox",
                    promo_code : random_code,
                    valid_date :myData.Expire,
                    discount : myData.Value,
                    symbol : '%'
                  } 
                },
            };

            const promoCode = await axios(config)

            if(promoCode.data.code){
                setIsLoading(false)
                setAllData({
                    email:"",
                    f_Name:"",
                    l_Name:"",
                    phoneNumber:"",
                })
                const data = {
                    user:allData.f_Name+ " " + allData.l_Name,
                    url : SERVER_URL + location.pathname + location.search,
                    merchant: myData.BelongsTo                
                }
                navigate(`/share_page?info=${Base64.encode(JSON.stringify(data))}`)
            }else{
                toast.error("Problem in sending email.")
            }
        }else{
            toast.error("Problem in generating code.")
            setIsLoading(false)
        }
      }

    const checkOutHandler = (e) =>{
        e.preventDefault()

        const data = {
            Email:allData.email,
            FirstName:allData.f_Name,
            LastName:allData.l_Name,
            Phone:allData.phoneNumber,
            Merchant:info.Merchant
        }

        scanForm.validate(data,{abortEarly:false})
        .then(async (response) =>{
            setIsLoading(true)
            try{
                const subscriber_already_exist = query(colRef,where('EmailAddress','==',allData.email))
                const doc_already_exist = await getDocs(subscriber_already_exist)
                if(doc_already_exist.size){
                    let subscriberData;
                    doc_already_exist.forEach((doc) => {
                        subscriberData = doc.data()
                        subscriberData.id = doc.id
                    });
                    addCustomerMerchantRelation({subscriber_id: subscriberData.id})
                }else{
                    const create_subscriber = await addDoc(colRef,{
                        EmailAddress:allData.email,
                        Phone:allData.phoneNumber,
                        Language:"",
                        PreferredMerchantID:"",
                        BirthDate:"",
                        EmailOptOut:false,
                        FirstName:allData.f_Name,
                        LastName:allData.l_Name,
                        IsActive:true
                    })
                    if(create_subscriber.id){
                        addCustomerMerchantRelation({subscriber_id: create_subscriber.id})
                    }else{
                        setIsLoading(false)
                        toast.error("Something Went Wrong while creating subscriber.")
                    }
                }
            }catch(err){
                console.log(err);
                setIsLoading(false)
            }
            setErrorMsg([])
        }).catch((error) =>{
            setErrorMsg(error.errors)
        })
    }
  return (
    <>
        <CustomerNav />
        <div className={styles.container}>
            <div className={styles.wrapper}>
                <form className={styles.form}>
                    <div className={styles.msg}>
                        <h1>Personal Details</h1>
                    </div>
                    <div className={styles.inputType}>
                        <div>
                            <input className={styles.input} type="text" required={true} value={allData.email} onChange={emailHandler}/>
                            <label>Email Address</label>
                        </div>
                        
                        {
                            errorMsg.map((item,index) =>(
                                <div style={{
                                    color:"red",
                                    fontWeight:"600",
                                    fontSize:"14px",
                                    marginTop:"3px"
                                }} key={index}>{item.email}</div>
                            ))
                            }
                    </div>
                    <div className={styles.inputType}>
                        <div>
                            <input className={styles.input} type="text" required={true} value={allData.f_Name} onChange={f_nameHandler} />
                            <label>First Name</label>
                        </div>
                        {
                            errorMsg.map((item,index) =>(
                                <div style={{
                                    color:"red",
                                    fontWeight:"600",
                                    fontSize:"14px",
                                    marginTop:"3px"
                                }} key={index}>{item.fname}</div>
                            ))
                            }
                    </div>
                    <div className={styles.inputType}>
                        <div>
                            <input className={styles.input} type="text" required={true} value={allData.l_Name} onChange={l_nameHandler} />
                            <label>Last Name</label>
                        </div>
                        {
                            errorMsg.map((item,index) =>(
                                <div style={{
                                    color:"red",
                                    fontWeight:"600",
                                    fontSize:"14px",
                                    marginTop:"3px"
                                }} key={index}>{item.lname}</div>
                            ))
                            }
                    </div>
                    <div className={styles.inputType}>
                        <div>
                            <input className={styles.input} type="tel" required={true} value={allData.phoneNumber} onChange={phoneHandler} />
                            <label>Phone Number</label>
                        </div>
                        {
                            errorMsg.map((item,index) =>(
                                <div style={{
                                    color:"red",
                                    fontWeight:"600",
                                    fontSize:"14px",
                                    marginTop:"3px"
                                }} key={index}>{item.phone}</div>
                            ))
                            }
                    </div>
                    <div className={styles.checkout}>
                        <button disabled={disabled} className={styles.checkbtn} onClick={checkOutHandler}> Checkout </button>
                    </div>
                </form>
            </div>
        </div>
        <Footer />
        {
          isLoading && <Loader />
        }
    </>
  )
}

export default QrScanForm