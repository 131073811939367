import React, { useState } from 'react'
import styles from "./ContactUs.module.css"
import NavBar from '../Partialpages/NavBar'
import Footer from "../Partialpages/Footer"
import { Link } from 'react-router-dom'
import { BASE_URL, SECRET_KEY } from '../../Constants'
import axios from 'axios'
import Loader from '../Utilities/Loader'
import { contactUs } from '../../Validations/HomeValidations'
import { toast } from 'react-toastify';
import { db } from '../../firebase/config'
import { collection,where,query, addDoc } from 'firebase/firestore'

function ContactUs() {
    const colRef = collection(db,"contact_us")
    const merchant_id = sessionStorage.getItem("merchantId")

    const Email = sessionStorage.getItem("merchantEmail")
    const Name = sessionStorage.getItem("storeName")
    const [isPopUp,setIsPopUp] = useState(false)
    const [errorMsg,setErrorMsg] = useState([]) 
    const [isLoading,setIsLoading] = useState(false)
    const [allData,setAllData] = useState({
        email:Email,
        description:"",
        name:Name,
    })
    
    const emailHandler = (e) =>{
        setAllData({
            ...allData,
            email:e.target.value,
        })
    }
    const nameHandler = (e) =>{
        setAllData({
            ...allData,
            name:e.target.value,
        })
    }
    const descriptionHandler = (e) =>{
        setAllData({
            ...allData,
            description:e.target.value,
        })
    }
    
    const enquiryHandler = (e) =>{
        e.preventDefault()
        
        const url = BASE_URL + "contact_us"
    
        const config = {
            headers:{
                "secret_key" : SECRET_KEY,
                "Content-Type" : "application/json",
            }
        }
        const data = {
            ...allData
        }

        contactUs.validate(data,{abortEarly:false})
        .then(async (response) =>{
            setIsLoading(true)

            try{
                const doc = await addDoc(colRef,{
                    Email: allData.email,
                    StoreName : allData.name,
                    Description : allData.description,
                    MerchantId : merchant_id
                })

                if(doc.id){
                    setAllData({
                        description:"",
                    })
                    setIsLoading(false)
                    setIsPopUp(true)
                }else{
                    toast.success("Problem in Contacting Us")
                    setIsLoading(false)
                }
            }catch(err) {
                setIsLoading(false)
                console.log(err)
            }
            setErrorMsg([])
        }).catch((error) =>{
            console.log(error.errors)
            setErrorMsg(error.errors)
        })

    }
    const closeHandler = () =>{
        setIsPopUp(false)
    }

  return (<>
        <NavBar />
        <div className={styles.container}>
            <div className={styles.wrapper}>
                <div className={styles.title}>
                    <h1>Contact Us</h1>
                    <p>We are happy to Help! <br></br>
                    Send us an inquiry in the box mentioned below.</p>
                </div>
                <div className={styles.message}>
                    <div className={styles.messageInside}>
                        <h1>Leave a message</h1>
                        <div className={styles.messageField}>
                            <div className={styles.inputType}>
                                <div>
                                    <input type="text" required={true}  onChange={emailHandler} value={allData.email}/>
                                    <label>Email Address</label>
                                </div>
                                {
                                    errorMsg.map((item,index) =>(
                                        <div style={{
                                            color:"red",
                                            fontWeight:"600",
                                            fontSize:"14px",
                                            marginTop:"3px"
                                        }} key={index}>{item.email}</div>
                                    ))
                                }
                            </div>
                            <div className={styles.inputType}>
                                <div>
                                    <input type="text" required={true} onChange={nameHandler} value={allData.name} />
                                    <label>Store Name</label>
                                </div>
                                {
                                    errorMsg.map((item,index) =>(
                                        <div style={{
                                            color:"red",
                                            fontWeight:"600",
                                            fontSize:"14px",
                                            marginTop:"3px"
                                        }} key={index}>{item.name}</div>
                                    ))
                                }
                            </div>
                            <div className={styles.textMsg}>
                                <div>
                                    <textarea required={true} onChange={descriptionHandler} value={allData.description}></textarea>
                                    <label>How can we help ?</label>
                                </div>
                                {
                                    errorMsg.map((item,index) =>(
                                        <div style={{
                                            color:"red",
                                            fontWeight:"600",
                                            fontSize:"14px",
                                            marginTop:"3px"
                                        }} key={index}>{item.des}</div>
                                    ))
                                }
                            </div>
                            <div className={styles.msgButton}>
                                <button onClick={enquiryHandler} >Send Enquiry</button>
                            </div>
                        </div>
                    </div>   
                </div>
            </div>
        </div>
        <Footer />
        {
            isLoading && <Loader />
            
        }
        {
          isPopUp && 
          <>
            <div className={styles.popupContainer}>
                <div className={styles.closeDiv}>
                    <div className={styles.close}> 
                        <i onClick={closeHandler} className="fa-sharp fa-solid fa-xmark"></i>
                    </div>
                    <div className={styles.popupWrapper}>
                        <div className={styles.popUpMsg}>
                            <h3>We received your message !</h3>
                            <h3>We will contact you soon !</h3>
                        </div>
                        <div className={styles.popupBtns}>
                            <Link to="/home"> Home </Link>
                        </div>
                    </div>
                </div>
            </div>
          </>
        }
    </>
  )
}

export default ContactUs