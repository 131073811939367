import React, { useState } from 'react'
import styles from "./GiveDiscount.module.css"
import { giveDiscount } from '../../../Validations/HomeValidations';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Loader from '../../Utilities/Loader';
import { toast } from 'react-toastify';
import { collection,where,query, getDocs, addDoc } from 'firebase/firestore'
import { db } from '../../../firebase/config'
import { random_string } from '../../Utilities/CommonFunctions';
import { PROMO_CODE_EMAIL_TEMPLATE } from '../../../Constants';

function GiveDiscount() {
    const colRef = collection(db,"subscribers")
    const merchantSubscriberColRef = collection(db,"merchant_subscriber_relations")
    const promoCodesColRef = collection(db,"promo_codes")

    const [discount,setDiscount] = useState(-1);
    const [percent,setPercent] = useState(false);
    const [dis_percent,setDis_percent] = useState(false);
    const [dis_dollar,setDis_dollar] = useState(false);
    const [expire,setExpire] = useState(false);
    const [errorMsg,setErrorMsg] = useState([]) 
    const [isLoading,setIsLoading] = useState(false)
    const navigate = useNavigate()
    const [flag,setFlag] = useState(false)
    const [cId,setCId] = useState()
    const [allData,setAllData] = useState({
    email:"",
    f_Name:"",
    l_Name:"",
    phoneNumber:"",
    discountType_percent:"",
    discountType_dollar:"",
    expireAfter:""
  })
  const emailHandler = (e) =>{
        setAllData({
            ...allData,
            email:e.target.value
        })
  }

    const f_nameHandler = (e) =>{
        setAllData({
            ...allData,
            f_Name:e.target.value
        })
    }
    const l_nameHandler = (e) =>{
        setAllData({
            ...allData,
            l_Name:e.target.value
        })
    }
    const phoneHandler = (e) =>{
        setAllData({
            ...allData,
            phoneNumber:e.target.value
        })
    }

    const discountHandler = (e) =>{

        if(percent){
            setAllData({
                ...allData,
                discountType_dollar:e.target.value
            })
        }else{
            setAllData({
                ...allData,
                discountType_percent:e.target.value
            })
        }
    }
    
  const selectExpire =(index,value)=>{
    setDiscount(index)
    if(value === "1 Week"){
        let date = new Date()
        date.setDate(new Date().getDate() + 7)
        
        setAllData({
            ...allData,
            expireAfter:date.toISOString().replace(/T.*/,'')
        })
    }else if(value === "2 Week"){
        let date = new Date()
        date.setDate(new Date().getDate() + 14)
        
        setAllData({
            ...allData,
            expireAfter:date.toISOString().replace(/T.*/,'')
        })
    }else if(value === "1 Month"){
        let date = new Date()
        date.setDate(new Date().getDate() + 30)
        
        setAllData({
            ...allData,
            expireAfter:date.toISOString().replace(/T.*/,'')
        })
    }else{
        let date = new Date()
        date.setDate(new Date().getDate() + 9999)
        
        setAllData({
            ...allData,
            expireAfter:date.toISOString().replace(/T.*/,'')
        })
    }
  }

  const merchant_id = sessionStorage.getItem("merchantId")

  const addCustomerMerchantRelation = async (data,again) => {
    const is_relation_exist = query(merchantSubscriberColRef,where('MerchantId','==',merchant_id),where('SubscriberId','==',data.subscriber_id))
    const docs = await getDocs(is_relation_exist)
    if(docs.size === 0 || again){
        const add_relation = await addDoc(merchantSubscriberColRef,{
            MerchantId : merchant_id,
            SubscriberId : data.subscriber_id,
            relationCreatedDate : new Date(),
            Active:true,
            subscriberData: {
                EmailAddress:allData.email,
                Phone:allData.phoneNumber,
                FirstName:allData.f_Name,
                LastName:allData.l_Name,
                IsActive:true,
            }
        })
        if(add_relation.id){
            generatePromoCode({customer_id : data.subscriber_id})
        }else{
            setIsLoading(false)
            toast.error('Something went wrong while creating relations.')
        }
    }else{
        setFlag(true)
        setIsLoading(false)
        toast.error('Customer already registered with the given email address.')
    }
  }

  const generatePromoCode = async (data) => {
    const random_code = random_string(6).toUpperCase()
    const generate_promo_code = await addDoc(promoCodesColRef,{
        MerchantID : merchant_id,
        SubscriberId : data.customer_id,
        CouponCode : random_code,
        IsClaimed: false,
        CreatedDate: new Date(),
        ClaimedDate: null,
        CouponValue : percent ? parseInt(allData.discountType_dollar) : parseInt(allData.discountType_percent),
        Expiry : allData.expireAfter,
        PromocodeType : percent ? "amount" : "percentage",
        subscriberData: {
            EmailAddress:allData.email,
            Phone:allData.phoneNumber,
            FirstName:allData.f_Name,
            LastName:allData.l_Name,
            IsActive:true,
        }
    })
    if(generate_promo_code.id){

        let config = {
            method: "post",
            maxBodyLength: Infinity,
            url: `https://tifinhub-api-hacsigy3hq-uc.a.run.app/foodflyer_email_trigger/send_email`,
            headers: {
              "Content-Type": "application/json",
            },
            data: {
              senderEmail : allData.email,
              subject : 'Promo Code',
              templateKey : PROMO_CODE_EMAIL_TEMPLATE,
              variables : {
                brand : "Scriptbox",
                promo_code : random_code,
                valid_date : allData.expireAfter,
                discount : percent ? parseInt(allData.discountType_dollar) : parseInt(allData.discountType_percent),
                symbol : percent ? '$' : '%'
              } 
            },
        };
        const promoCode = await axios(config)

        if(promoCode.data.code){
        setIsLoading(false)
            setAllData({
                email:"",
                f_Name:"",
                l_Name:"",
                phoneNumber:"",
                discountType_percent:"",
                discountType_dollar:"",
                expireAfter:""
            })
            setDiscount(-1)
            toast.success("Coupon Generated Successfully. Please check your Email")
        }else{
        toast.error("Problem in sending email.")
        }
    }else{
        setIsLoading(false)
        toast.error('Something went Wrong while generating code.')
    }
  }

  const checkOutHandler = (e,again = false) =>{
    e.preventDefault()

    allData.expireAfter === "" ? setExpire(true) : setExpire(false)

    percent ? (allData.discountType_dollar === "" ? setDis_dollar(true) : setDis_dollar(false)) :
        (allData.discountType_percent === "" ? setDis_percent(true) : setDis_percent(false))

    const data = {
        FirstName:allData.f_Name,
        LastName:allData.l_Name,
        Phone:allData.phoneNumber,
        Email:allData.email
    }

    giveDiscount.validate(data,{abortEarly:false})
    .then(async (response) =>{
        if(percent ? allData.discountType_dollar === "" : allData.discountType_percent === "" || allData.expireAfter === ""){
            console.log("wrong")
        }else{
            setIsLoading(true)

            try{
                const subscriber_already_exist = query(colRef,where('EmailAddress','==',allData.email))
                const doc_already_exist = await getDocs(subscriber_already_exist)
                if(doc_already_exist.size){
                    let subscriberData;
                    doc_already_exist.forEach((doc) => {
                        subscriberData = doc.data()
                        subscriberData.id = doc.id
                    });
                    addCustomerMerchantRelation({subscriber_id: subscriberData.id},again)
                }else{
                    const create_subscriber = await addDoc(colRef,{
                        EmailAddress:allData.email,
                        Phone:allData.phoneNumber,
                        Language:"",
                        PreferredMerchantID:"",
                        BirthDate:"",
                        EmailOptOut:false,
                        FirstName:allData.f_Name,
                        LastName:allData.l_Name,
                        IsActive:true
                    })
                    if(create_subscriber.id){
                        addCustomerMerchantRelation({subscriber_id: create_subscriber.id})
                    }else{
                        setIsLoading(false)
                        toast.error("Something Went Wrong while creating subscriber.")
                    }
                }
            }catch(err){
                console.log(err);
                setIsLoading(false)
            }
        }
        setErrorMsg([])
    }).catch((error) =>{
        setErrorMsg(error.errors)
    })
  }
  const closeHandler = () =>{
    setFlag(false)
  }

  const generateAgain = (e) =>{
    setFlag(false)
    checkOutHandler(e,true)
  }
  return (
    <>  
        <div className={styles.tab}>
        <div className={styles.formPart}>
            <div className={styles.inputType}>
                <div>
                    <input type="text" required={true} value={allData.email} onChange={emailHandler}/>
                    <label>Email Address</label>
                </div>
                
                    {
                        errorMsg.map((item,index) =>(
                            <div style={{
                                color:"red",
                                fontWeight:"600",
                                fontSize:"14px",
                                marginTop:"3px"
                            }} key={index}>{item.email}</div>
                        ))
                    }
            </div>
            <div className={styles.inputType}>
                <div>
                    <input type="text" required={true} value={allData.f_Name} onChange={f_nameHandler} />
                    <label>First Name</label>
                </div>
                {
                    errorMsg.map((item,index) =>(
                        <div style={{
                            color:"red",
                            fontWeight:"600",
                            fontSize:"14px",
                            marginTop:"3px"
                        }} key={index}>{item.fname}</div>
                    ))
                    }
            </div>
            <div className={styles.inputType}>
                <div>
                    <input type="text" required={true} value={allData.l_Name} onChange={l_nameHandler} />
                    <label>Last Name</label>
                </div>
                {
                    errorMsg.map((item,index) =>(
                        <div style={{
                            color:"red",
                            fontWeight:"600",
                            fontSize:"14px",
                            marginTop:"3px"
                        }} key={index}>{item.lname}</div>
                    ))
                    }
            </div>
            <div className={styles.inputType}>
                <div>
                    <input type="tel" required={true} value={allData.phoneNumber} onChange={phoneHandler} />
                    <label>Phone Number</label>
                </div>
                {
                    errorMsg.map((item,index) =>(
                        <div style={{
                            color:"red",
                            fontWeight:"600",
                            fontSize:"14px",
                            marginTop:"3px"
                        }} key={index}>{item.phone}</div>
                    ))
                    }
            </div>
            <div className={styles.discountType}>
                <p>Discount Type</p>
                <div className={styles.switchButton}>
                    <input className={styles.switchButtonCheckbox} type="checkbox" onChange={(e) =>{setPercent(e.target.checked)}} ></input>
                    <label className={styles.switchButtonLabel} ><span className={styles.switchButtonLabelSpan}>Percentage</span></label>
                </div>
            </div>
            <div className={styles.inputType}>
                <div>
                    <input type="text" required={true} value={percent ? allData.discountType_dollar : allData.discountType_percent} onChange={discountHandler}/>
                    {
                    percent ? <label>Dollar</label> :
                            <label>Percentage</label>
                    }
                </div>
                {
                    percent ? <>
                        {
                    
                        dis_dollar &&
                            <div style={{
                                color:"red",
                                fontWeight:"600",
                                fontSize:"14px",
                                marginTop:"3px"
                            }}>**This field is required </div>
        
                        }
                    </>
                :<>
                    {
                    
                    dis_percent &&
                        <div style={{
                            color:"red",
                            fontWeight:"600",
                            fontSize:"14px",
                            marginTop:"3px"
                        }}>**This field is required </div>

                    }
                
                </>
                    }
            </div>
            <div className={styles.exp}>
                <p>Expire After</p>
                <div className={styles.expire}>
                    <button onClick={() => selectExpire(0,"1 Week")} className={discount === 0 ? styles.active : undefined} >1 Week</button>
                    <button onClick={() => selectExpire(1,"2 Week")} className={discount === 1 ? styles.active : undefined} >2 Week</button>
                    <button onClick={() => selectExpire(2,"1 Month")} className={discount === 2 ? styles.active : undefined} >1 Month</button>
                    <button onClick={() => selectExpire(3,"Never")} className={discount === 3 ? styles.active : undefined} > Never </button>
                </div>
                {
                    expire &&
                        <div style={{
                            color:"red",
                            fontWeight:"600",
                            fontSize:"14px",
                            marginTop:"3px"
                        }}>**Please select one option </div>
                
                    }
            </div>
            <div className={styles.checkout}>
                <button className={styles.checkbtn} onClick={checkOutHandler}> Checkout </button>
            </div>
        </div>
        </div>
        {
            isLoading && <Loader />
            
        }
    {
        flag &&
        
        <>
        <div className={styles.popupContainer}>
            <div className={styles.closeDiv}>
                <div className={styles.close}> 
                    <i onClick={closeHandler} className="fa-sharp fa-solid fa-xmark"></i>
                </div>
                <div className={styles.popupWrapper}>
                    <div className={styles.popUpMsg}>
                        <h3>Coupon already Generated </h3>
                        <h3>with given email Address!</h3>
                    </div>
                    <div className={styles.popupBtns}>
                        <button onClick={generateAgain}> Generate Again </button>
                    </div>
                </div>
            </div>
        </div>
      </>
    }
    </>
  )
}

export default GiveDiscount