import './App.css';
import {Routes,Route} from "react-router-dom"
import Login from './Components/Authentication/Login';
import Register from "./Components/Authentication/Register"
import Home from "./Components/Pages/Home/Home"
import ContactUs from './Components/Pages/ContactUs'
import Settings from './Components/Pages/UpdatePage/Settings';
import MerchantPolicy from "./Components/Pages/MerchantPolicy"
import ForgotPassword from './Components/Authentication/ForgotPassword';
import QrCode from "./Components/Pages/QrCode"
import CouponReports from './Components/Pages/CouponReports';
import AccApprove from './Components/Pages/AccApprove';
import Preference from "./Components/Pages/Preference"
import { createContext,useState} from 'react';
import QrScanForm from './Components/Pages/QrScanForm';
import EmailVerify from './Components/Authentication/EmailVerify';
import UpdatePassword from './Components/Authentication/UpdatePassword';
import SubscriberReports from './Components/Pages/SubscriberReports';
import Share from './Components/Pages/Share';
import QrCodeExpire from './Components/Pages/QrCodeExpire';
import { ToastContainer } from 'react-toastify';
export const LoginStatus = createContext()
export const Flag = createContext()
export const IsQrCode = createContext()


function App() {

  const [isLogin,setIsLogin] = useState(false)
  const [flag,setFlag] = useState(false)
  const [isQrCode,setIsQrCode] = useState(false)

  return (
    <>
    <LoginStatus.Provider value={{isLogin,setIsLogin,flag,setFlag}}>
        <IsQrCode.Provider value={{isQrCode,setIsQrCode}}>
          <Routes>
            <Route path='/' element={<Login />}/>
            <Route path='/register' element={<Register />}/>
            <Route path='/home' element={<Home />}/>
            <Route path='/preference_page/:id' element={<Preference />}/>
            <Route path='/contactus' element={<ContactUs />}/>
            <Route path='/settings' element={<Settings />}/>
            <Route path='/merchant_policy' element={<MerchantPolicy />}/>
            <Route path='/forgot_password' element={<ForgotPassword />}/>
            <Route path='/qr_code' element={<QrCode />}/>
            <Route path='/coupon_reports' element={<CouponReports />}/>
            <Route path='/subscriber_reports' element={<SubscriberReports />}/>
            <Route path='/account_approve' element={<AccApprove />}/>
            <Route path='/qr_scan_form' element={<QrScanForm />}/>
            <Route path='/verify_email/:id' element={<EmailVerify />}/>
            <Route path='/update_forgot_password/:id' element={<UpdatePassword />}/>
            <Route path='/share_page' element={<Share />}/>
            <Route path='/expire_qr_code' element={<QrCodeExpire />}/>
          </Routes>
        </IsQrCode.Provider>
    </LoginStatus.Provider>
    <ToastContainer 
            position="bottom-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light" />
    </>
  )
}

export default App;
