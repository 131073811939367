import * as yup from "yup"

export const giveDiscount = yup.object().shape({
    Email:yup.string().email({email:"**Invalid email address"}).required({email:"**This field is required"}),
    FirstName:yup.string().required({fname:"**This field is required"}),
    LastName:yup.string().required({lname:"**This field is required"}),
    Phone: yup.string().min(10,{phone:"**Phone no. must be 10 char"}).max(10,{phone:"**Phone no. must be 10 char"}).required(),
})

export const dis_code = yup.object().shape({
    dis_code:yup.string().required({code:"**This field is required"})
})

export const update_qr = yup.object().shape({
    dis_percent:yup.string().required({per:"**This field is required"}),
})


export const scanForm = yup.object().shape({
    Email:yup.string().email({email:"**Invalid email address"}).required({email:"**This field is required"}),
    FirstName:yup.string().required({fname:"**This field is required"}),
    LastName:yup.string().required({lname:"**This field is required"}),
    Phone: yup.string().min(10,{phone:"**Phone no. must be 10 char"}).max(10,{phone:"**Phone no. must be 10 char"}).required(),
})


export const contactUs = yup.object().shape({
    email:yup.string().email({email:"**Invalid email address"}).required({email:"**This field is required"}),
    name:yup.string().required({name:"**This field is required"}),
    description:yup.string().required({des:"**This field is required"}),
})

export const socialMedia = yup.object().shape({
    tiktok:yup.string().required({tiktok:"**This field is required"}),
    instagram:yup.string().required({instagram:"**This field is required"}),
    facebook:yup.string().required({facebook:"**This field is required"}),
    twitter:yup.string().required({twitter:"**This field is required"}),
})
