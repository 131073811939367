import React,{useEffect, useState} from 'react'
import GiveDiscount from "./GiveDiscount"
import VerifyDiscount from "./VerifyDiscount"
import UpdateQr from "./UpdateQr"
import styles from "./Home.module.css"
import NavBar from "../../Partialpages/NavBar"
import Footer from "../../Partialpages/Footer"
import { useSearchParams } from 'react-router-dom'
import {IsQrCode} from "../../../App"
import { useContext } from 'react'
import { toast } from 'react-toastify';
import { collection,where,query, getDocs } from 'firebase/firestore'
import { db } from '../../../firebase/config'

function Home() {
    const colRef = collection(db,"qr_code_details")
    const [params,setParams] = useSearchParams()
    const [tab, setTab] = useState(0)
    const isQrCode = useContext(IsQrCode)
    const tabControl = (val) =>{
        setTab(val)
    }

    const fetchData = async () =>{
        const merchant_id = sessionStorage.getItem("merchantId")
        const find_qr_code = query(colRef,where('MerchantId', "==",merchant_id))
        try{
            const doc = await getDocs(find_qr_code)
            if(doc.size){
                let qrData;
                doc.forEach((doc) => {
                    qrData = doc.data()
                });
                isQrCode.setIsQrCode(qrData.isQrCodeGenerated) 
            }else{
                isQrCode.setIsQrCode(false) 
            }
        }catch(err){
            console.log(err)
        }
    }
    var count = 1;
    useEffect(() =>{
        if(params.get("tab") !== null){
            setTab(parseInt(params.get("tab")))
            setTimeout(() =>{
                params.delete("tab")
                setParams(params)
            },500)
        }
        fetchData()
        if(count === 1){
            count++
            if(params.get("flag") !== null){
                toast.success(params.get("msg"))
                setTimeout(() =>{
                    params.delete("flag")
                    setParams(params)
                },500)
            } 
        }
    },[])

  return (
    <>
        <NavBar />
        <div className={styles.section}>
            <div className={styles.rightPart}>
                <div className={styles.wrapper}>
                    <div className={styles.buttons}>
                        <button className={`${styles.btn} ${tab === 0 && styles.btnBefore}`} onClick={() => tabControl(0)}>Give Discount</button>
                        <button  className={`${styles.btn} ${tab === 1 && styles.btnBefore}`}  onClick={() => tabControl(1)}>Verify Discount</button>
                        {
                            isQrCode.isQrCode ?
                            <button className={`${styles.btn} ${tab === 2 && styles.btnBefore}`}  onClick={() => tabControl(2)}>Update QR </button> :
                            <button className={`${styles.btn} ${tab === 2 && styles.btnBefore}`}  onClick={() => tabControl(2)}>Generate QR </button>
                        }
                    </div>
                    <div className={styles.tabCont}>
                        <div className={styles.tabs}>
                        {
                            tab === 0 && <GiveDiscount />
                        }
                        {
                            tab === 1 && <VerifyDiscount />
                        }
                        {
                            tab === 2 && <UpdateQr /> 
                        }
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </>
  )
}

export default Home