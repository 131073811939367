import React from 'react'
import styles from "./MerchantPolicy.module.css"
import NavBar from '../Partialpages/NavBar';
function MerchantPolicy() {
  return (
    <div className={styles.container}>
        <NavBar />
        <div className={styles.wrapper}>
            <div className={styles.head}>
                <h1>Merchant's <span>Terms & Conditions</span></h1>
            </div>
            <div className={styles.content}>
                <div className={styles.pp}>
                    <h1>Privacy Policy</h1>
                    <ul>
                        <li>We are subject to modify our terms and condition without any prior notice to the customers.</li>
                        <li>Our site is collecting the user information with the help of the register button and we expect the information provided by the user to be true and authentic so that we can contact our customer in case of any plan issues.</li>
                        <li>We hereby commit that by subscribing to our promotions we will not be sharing your information with any third party as your privacy is our main concern. The following terms are applicable for any products that You purchased with Us.</li>
                    </ul>
                </div>
            
                    <div className={styles.iau}>
                        <h1>1.&nbsp;INFORMATION ABOUT US</h1>
                        <h2>Interpretation and Definitions</h2>
                        <h2>Interpretation </h2>
                        <p>The words in which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or plural.</p>
                        <div className={styles.def}>
                            <h2>Definitions</h2>
                            <p>For the purposes of this Return and Refund Policy : </p>
                            <p>Company (referred to as either "the Company", "We", "Us" or "Our"; in this Agreement) refers to Tiffinhub, 123 Macbeth. Which is the sole proprietorship of its owner.</p>
                            <p>You mean the customer, the individual, accessing or using the Service to make an Order. The individual may access the Service on the behalf of another person, at their own responsibility.</p>
                        </div>
                        <div className={styles.keyPoints}>
                            <p><b>Product</b> implies the items offered for sale on the Service : </p>
                            <p><b>Meal</b> refers to the items offered for sale on the Service which is delivered to Your Address.</p>
                            <p><b>Plan</b> or Meal Plan refers to the stipulated arrangement of meals delivered to your address over a specific
                            period of time, as established in our weekly, biweekly, and monthly packages on our Website.</p>
                            <p><b>Order</b> means a request by You to purchase Meals or Plans from Us.</p>
                            <p><b>Service</b>/Our Service refers to the Website, which is Foodflyer, accessible from <a href='#'>Foodflyer.ca</a></p>
                            <p><b>Hold</b> implies the action of pausing the delivery of meals in a Plan</p>
                        </div>
                        <br></br>
                        <br></br>
                        <p>Please read these terms and conditions carefully before placing an order with Us. You should understand that by placing an order, you agree to be bound by these terms and conditions.</p>
                        <br></br>
                        <br></br>
                        <p>Please accept these terms and conditions when prompted while making an Order. Please understand that if you refuse to accept these terms and conditions, you will not be able to place an Order with the US.</p>
                    </div>
             
                    <div className={styles.sa}>
                        <h1>2.&nbsp;SERVICE AVAILABILITY</h1>
                        <br></br>
                        <br></br>
                        <p>Our Service is only intended for use by people residing in GTA. We do not accept orders from individuals outside the Serviced Territory.</p>
                    </div>
                    <div className={styles.sa}>
                        <h1>3.&nbsp;HOW THE CONTRACT IS FORMED BETWEEN YOU AND US</h1>
                        <br></br>
                        <br></br>
                        <p>After placing an Order through our standard order form on our Service, you will receive an e-mail from us acknowledging that we have received your order (Acknowledgement). Please note that this does not mean that your order has been accepted. Your Order consists of an offer to us to purchase our product, subject to these terms and conditions. All Orders are subject to acceptance by us, and we will confirm such acceptance to you by sending you an e-mail that confirms that your Order has been accepted (Confirmation). We may choose not to accept any Orders at our sole discretion. The contract between us (Contract) will only be formed when we send you the Confirmation.</p>
                        <br></br>
                        <br></br>
                        <p>Customers can also cancel or hold plan anytime they want, subject to the holds and cancelation policy as described below</p>
                        <br></br>
                        <p>By submitting your email to Foodflyer you are indicating an interest in Foodflyer and may receive email marketing communications.</p>
                        <br></br>
                        <br></br>
                        <div>
                            <h2>3.1 &nbsp; Order Cancellation</h2>
                            <p>If for any reason, You have to cancel your Order or put meal Plans on hold, We invite You to review our policy on Holds and Cancellations.</p>
                            <br></br>
                            <br></br>
                        </div>
                        <div>
                            <h2>Your Order Cancellation rights</h2>
                            <p>You can opt to Order Meals for a particular day, or multiple days by Ordering a Plan. By purchasing a Plan, payment for all the Meals specified in the Plan is done in advance, in a one-time payment.</p>
                            <p>You are entitled to cancel your order after purchase without giving any reason for doing so and receive a refund of the full amount for the remaining consecutive Meals, subject to the conditions below.</p>
                            <br></br>
                            <br></br>
                        </div>
                        <div>
                            <h2>Order Cancellation conditions</h2>
                            <p>To exercise Your right to cancellation of your Order, You must inform Us of your decision by means of the 'Cancel Plan' or 'Cancel Order' button on our website and agreeing to the subsequent prompts on our Website.</p>
                            <p>You must cancel your Order before 12:00 pm EST on any particular day so as to not receive delivery of a Meal that day and, hence be refunded for that day. Failing to request Cancellation before the deadline will result in your Meal being delivered to You, and the price incurred to You. Nevertheless, the subsequent Meals in the Plan shall be canceled and the relevant refund processed.</p>
                            <p>We will reimburse You your outstanding balance no later than 5 days from the day on which You placed a cancellation request. We will use the same means of payment as You used for the Order, and You will not incur any fees for such reimbursement.</p>
                            <br></br>
                            <br></br>
                        </div>
                        <div>
                            <h2>Returns</h2>
                            <p>Please note that We do not accept return requests for Meals not canceled before the deadline or for the meals already delivered.</p>
                            <br></br>
                            <br></br>
                        </div>
                        <div>
                            <h2>3.2 &nbsp;Hold</h2>
                            <p>We pride in giving you the provision to pause a plan at any time. You are at liberty to pause and resume receiving Meals on a plan, provided that You inform us of your intention to put your plan on hold, for this you have to follow these simple steps: navigate to your <a style={{color:"#06C167",textDecoration:"none",fontWeight:"bold"}} href='#'>Profile</a>, then click on plans, after that click on <b>“HOLD”</b> on our website. You must place a Hold request before 12:00 pm EST in order to not receive a meal for that day. Failing to request a Hold before the deadline will result in your Meal being delivered to You, and the price incurred to You. Nevertheless, the subsequent Meals in the Plan shall be put on Hold. You shall then be able to request to resume receiving meals on the Plan on your outstanding balance at no extra costs.</p>
                            <br></br>
                            <br></br>
                        </div>
                    </div>
                    <div className={styles.gc}>
                        <h1>4.&nbsp;GIFT CARDS</h1>
                        <br></br>
                        <br></br>
                        <p>We offer gift cards to the user which they can use at the time of payment, these gift cards are valid for one-time purchase only and it requires the user to fill in the unique code at the time of checkout.</p>
                    </div>
                    <div className={styles.pay}>
                        <h1>5.&nbsp;PAYMENT</h1>
                        <br></br>
                        <br></br>
                        <p>We charge the price of the meals as described on our site and we will not charge you further for anything without your consent.</p>
                        <p>Payment for all Products must be by credit card or Debit. We accept payment with American Express, Visa, Mastercard as well. We reserve the right to change the payment methods we accept at any time.</p>
                    </div>
                    <div className={styles.rp}>
                        <h1>5.&nbsp;REFUND POLICY</h1>
                        <br></br>
                        <br></br>
                        <p>We will process the refund due to you as soon as possible and, in any case, within 5 days of the day you gave notice of deactivation. In this case, we will refund the price of the Product in full and any applicable delivery charges.</p>
                        <p>For any other reason, we will notify you of your refund, if any, via phone or e-mail within a reasonable period of time.</p>
                    </div>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
            </div>
        </div>
    </div>
  )
}

export default MerchantPolicy