import React from 'react'
import CustomerNav from '../Partialpages/CustomerNav'
import { Base64 } from '../../EncodeDecode/index';
import {useSearchParams } from 'react-router-dom'
import Footer from "../Partialpages/Footer"
import style from "./Share.module.css"
import {
    FacebookShareButton,
    FacebookIcon,
    WhatsappShareButton,
    WhatsappIcon,
    TwitterShareButton,
    TwitterIcon,
    InstapaperShareButton,
    InstapaperIcon,
    TelegramShareButton,
    TelegramIcon,
} from "react-share"

function Share() {
    const [params] = useSearchParams()
    const info = JSON.parse( Base64.decode(params.get("info")))
    const message = `Hey! Do you need discount Coupon from ${info.merchant} ? Here is the form. Just subscribe and get the coupon on your email. Enjoy the discount. `
    console.log(info);
  return (
    <>
        <CustomerNav />
        <div className={style.shareContainer}>
            <div className={style.shareWrapper}>
                {/* <div className={style.shareLeft}>
                    <div className={style.gifImg}>
                        <img src="/images/food_anim.gif" alt="gif" />
                    </div>
                </div> */}
                <div className={style.shareRight}>
                    <div className={style.message}>
                        <h3 style={{textAlign:"center"}}>
                            <h1> Thank You!</h1>
                        <br></br>
                        <h1>{info.user}</h1> 
                        <br></br>
                        We sent the coupon to the given email, please check your email.
                        <br></br>
                        Share with your friends.
                        </h3>
                    </div>
                    <div className={style.icon}>
                        <div>
                            <WhatsappShareButton title={message} url={info.url}>
                                <WhatsappIcon round spacing={20} size={50}></WhatsappIcon>
                            </WhatsappShareButton>
                        </div>
                        <div>
                            <InstapaperShareButton title={message} url={info.url}>
                                <InstapaperIcon round size={50}></InstapaperIcon>
                            </InstapaperShareButton>
                        </div>
                        <div>
                            <FacebookShareButton title={message} url={info.url}>
                                <FacebookIcon size={50} round></FacebookIcon>
                            </FacebookShareButton>
                        </div>
                        <div>
                            <TwitterShareButton title={message} url={info.url}>
                                <TwitterIcon round size={50}></TwitterIcon>
                            </TwitterShareButton>
                        </div>
                    </div>
                </div>
            <div>
        </div>
        </div>
        </div>
       <Footer style={
        {
            marginTop:"0"     
        }
       } />
    </>
  )
}

export default Share