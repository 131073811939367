import React, { useContext, useEffect } from 'react'
import{useNavigate,useParams} from "react-router-dom"
import axios from 'axios'
import { LoginStatus } from '../../App';
import { BASE_URL, SECRET_KEY } from '../../Constants';
import { db } from '../../firebase/config'
import { doc, updateDoc } from 'firebase/firestore'

function EmailVerify() {
    const navigate = useNavigate()
    const isLogin = useContext(LoginStatus)
    const {id} = useParams()

    const verifyEmail = async () =>{
        try{
            const docRef = doc(db,"merchants",id)
            await updateDoc(docRef,{
                EmailVerified : true
            })
            isLogin.setIsLogin(false)
            navigate(`/?msg=Email verified successfully.&flag=success`)

        }catch(err){
            console.log(err);
            isLogin.setIsLogin(false)
            navigate(`/?msg=Something went wrong while verifying your email.&flag=fail`)
        }
    }

    useEffect(()=>{
        verifyEmail()
    },[])

  return (
    <></>
  )
}

export default EmailVerify