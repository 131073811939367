import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { BASE_URL, SECRET_KEY } from '../../Constants'
import Footer from '../Partialpages/Footer'
import NavBar from '../Partialpages/NavBar'
import styles from "./SubscriberReports.module.css"
import Loader from '../Utilities/Loader'
import { Table } from 'antd';
import { db } from '../../firebase/config'
import { collection,where,query, getDocs } from 'firebase/firestore'

function SubscriberReports() {
  const colRef = collection(db,"merchant_subscriber_relations")
  const merchant_id = sessionStorage.getItem("merchantId")
  
  const [isLoading,setIsLoading] = useState(false)
  const [subscriber,setSubscriber] = useState([])
  
  
  const getAllSubscribers = async () => {
    setIsLoading(true)
    try{
      const find_all_subscribers = query(colRef,where('MerchantId', "==",merchant_id))
      const docs = await getDocs(find_all_subscribers)
      if(docs.size > 0){
        const data = [];
        let count = 0
        docs.forEach((doc) => {
          count = count + 1
          let Name = `${doc.data().subscriberData.FirstName} ${doc.data().subscriberData.LastName}`
          data.push({
            key: count,
            email: doc.data().subscriberData.EmailAddress,
            full_name : Name,
            phone: doc.data().subscriberData.Phone,
            status : doc.data().subscriberData.IsActive ? "Active" : "Inactive"
          });
        })
        setIsLoading(false)
        setSubscriber(data)
      }else{
        setIsLoading(false)
      }
    }catch(err){
      console.log(err);
      setIsLoading(false)
    }
  }

  useEffect(() =>{
    getAllSubscribers()
  },[])


  const columns = [
    {
        title: "Sr No.",
        dataIndex: "key",
        align: "left",
      },
      {
        title: "Full Name",
        dataIndex: "full_name",
        align: "left",
      },
      {
        title: "Email",
        dataIndex: "email",
        align: "left",
      },
      {
        title: "Phone",
        dataIndex: "phone",
        align: "left",
      },
      {
        title: "Status",
        dataIndex: "status",
        align: "left",
      },
  ];
  return (
    <>
       <NavBar />
        <div className={styles.container}>
          <div className={styles.wrapper}>
            <div className ={styles.heading}>
              <h1>Subscribers Report</h1>
            </div>
            <div className={styles.content}>
              <div className={styles.tableWrapper}>

                  <div
                        style={{ overflowX: "auto" }}
                        >
                        <Table
                            columns={columns}
                            dataSource={subscriber}
                            pagination={{
                            pageSizeOptions: [
                                "5",
                                "10",
                                "30",
                                "60",
                                "100",
                            ],
                            showSizeChanger: true,
                            }}
                            scroll={{x:true}}
                            style={{ whiteSpace: "pre" }}
                            exportFileName="reports"
                        />
                        </div>
                
              </div>
            </div>
          </div>
        </div>
      <Footer />
      {
          isLoading && <Loader />
        }
    </>
  )
}

export default SubscriberReports