import React,{useEffect,useContext, useState} from 'react'
import {Link,useNavigate,useLocation} from "react-router-dom"
import styles from "./NavBar.module.css"
import {LoginStatus} from "../../App"

function NavBar() {

  const isLogin = useContext(LoginStatus)
  const [fullName,setFullName] = useState("")
  const navigate = useNavigate()
  const loc = useLocation()

  useEffect(()=>{
    let val = sessionStorage.getItem("merchantToken")
    if(val !== null){
      isLogin.setIsLogin(true)
      navigate(loc)
    }else{
      if(loc.pathname === "/register"){
        navigate("/register")
      }else if(loc.pathname === "/account_approve"){
        navigate("/account_approve")
      }else if(loc.pathname === "/merchant_policy"){
        navigate("/merchant_policy")
      }else if(loc.pathname === "/forgot_password"){
        navigate("/forgot_password")
      }else if(loc.pathname === "/expire_qr_code"){
        navigate('/expire_qr_code')
      }else{
        navigate("/")
      }
    }
    setFullName(sessionStorage.getItem("storeName"))

  },[])

  const logoHandler = () =>{
    navigate("/home")
  }

  const logoutHandler = () =>{
    isLogin.setIsLogin(false)
    sessionStorage.clear()
  }

  return (
    <nav className={styles.nav}>
      <div className={styles.rightSec}>
          <h1 onClick={logoHandler}>Scriptbox</h1>
      </div>
      {
        isLogin.isLogin &&
        <div className={styles.leftControls}>
            <span className={styles.userName}>{fullName} </span>
              <div style={{position:"relative"}}>
                <div className={styles.dropdown}>
                  <button className={styles.dropBtn}><i className="fa-solid fa-user"></i> &nbsp;&nbsp;
                    <i className="fa-solid fa-chevron-down"></i>
                  </button>
                  <div className={styles.dropdownContent}>
                    <Link to="/home">Home</Link>
                    <Link to="/settings">Settings</Link>
                    <Link className={styles.parentSubMenu}>
                      Reports
                      <ul className={styles.subMenu}>
                        <li><Link to="/subscriber_reports">Subscriber</Link></li>
                        <li><Link to="/coupon_reports">Promocode</Link></li>
                      </ul>
                    </Link>
                    <Link to="/contactus">Contact</Link>
                    <Link to="/" onClick={logoutHandler} >Logout</Link>
                  </div>
                </div> 
              </div>
        </div>
      }
    </nav>
  )
}

export default NavBar