import React,{useContext, useEffect, useState} from 'react'
import styles from "./Login.module.css"
import { Link,useNavigate, useSearchParams } from 'react-router-dom'
import { login } from '../../Validations/Authentication'
import axios from 'axios'
import Loader from '../Utilities/Loader'
import { LoginStatus } from '../../App'
import {SECRET_KEY,BASE_URL} from "../../Constants/index"
import {  toast } from 'react-toastify';
import { collection,where,query, getDocs } from 'firebase/firestore'
import { db } from '../../firebase/config'
import { random_string } from '../Utilities/CommonFunctions'
import { Base64 } from '../../EncodeDecode'

function Login() {
    const colRef = collection(db,"merchants")

    const [params,setParams] = useSearchParams()
    const isLogin = useContext(LoginStatus)
    const [errorMsg,setErrorMsg] = useState([]) 
    const [isLoading,setIsLoading] = useState(false)
    const navigate = useNavigate()
    const [email,setEmail] = useState("")
    const [password,setPassword] = useState("")
    const [passwordType,setPasswordType] = useState("password")

    var count = 1
    useEffect(() =>{
        if(count === 1){
            count++
            if(params.get("flag") !== null){
                if(params.get("flag") === "success"){
                    toast.success(params.get("msg"))
                    setTimeout(() =>{
                        params.delete("flag")
                        setParams(params)
                    },500)
                }else{
                    toast.error(params.get("msg"))
                    setTimeout(() =>{
                        params.delete("flag")
                        setParams(params)
                    },500)
                }
            }
        }
        
    },[])

    const showHide = () =>{
        if(passwordType === "password"){
            setPasswordType("text")
        }else{
            setPasswordType("password")
        }
    }

    const loginHandler = (e) =>{
        e.preventDefault()
        const data = {
            email:email,
            password:password
        }

        login.validate(data,{abortEarly:false})
        .then(async (result) =>{
            setIsLoading(true)
            try{
                const find_user = query(colRef,where('MerchantEmailAddress' ,"==", data.email))
                const doc = await getDocs(find_user)
                if(doc.size){
                    let userData;
                    doc.forEach((doc) => {
                        userData = doc.data()
                        userData.id = doc.id
                    });
                    if(userData && Base64.decode(userData.MerchantPassword) === data.password){
                        if(userData.EmailVerified){
                            if(userData.AccountApproved){
                                sessionStorage.setItem("merchantToken",random_string(20))
                                sessionStorage.setItem("merchantFname",userData.FirstName)
                                sessionStorage.setItem("merchantLname",userData.LastName)
                                sessionStorage.setItem("storeName",userData.MerchantName)
                                sessionStorage.setItem("merchantEmail",userData.MerchantEmailAddress)
                                sessionStorage.setItem("merchantId",userData.id)
                                setIsLoading(false)
                                isLogin.setIsLogin(true)
                                navigate(`/home?msg=Login successfully.&flag=success`)   
                            }else{
                                setIsLoading(false)
                                navigate("/account_approve")
                            }
                        }else{
                            isLogin.setIsLogin(false)
                            setIsLoading(false)
                            toast.error("Please Verify Your Email First.")
                            navigate("/")
                        }
                    }else{
                        isLogin.setIsLogin(false)
                        setIsLoading(false)
                        toast.error("Invalid email or password.")
                        navigate("/")
                    }
                }else{
                    isLogin.setIsLogin(false)
                    setIsLoading(false)
                    toast.error("Invalid email address. Please try to register first.")
                    navigate("/")
                }

            }catch(err) {
                isLogin.setIsLogin(false)
                setIsLoading(false)
                console.log(err)
            }
            setErrorMsg([])
        }).catch((error) =>{
            setErrorMsg(error.errors)
        })
    }

    const emailHandler = (e) =>{
        setEmail(e.target.value)
    }

    const passHandler = (e) =>{
        setPassword(e.target.value)
    }
  return (
    <>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <div className={styles.left}>
            <div className={styles.gifImg}>
              <img src="/images/food_anim.gif" alt="gif" />
            </div>
            <div className={styles.message}>
                <h1>Start saving</h1>
                <h1>your time & money</h1>
            </div>
          </div>
          <div className={styles.right}>
            <div className={styles.loginForm}>
                <form>
                  <div className={styles.formContainer}>
                      <div className={styles.welcome}>
                          <h1>Welcome back</h1>
                          <p>Collect More Grow More</p>
                      </div>    
                      <div className={styles.inputContainer}>
                          <div className={styles.inputField}>
                              <div>
                                  <div>
                                      <input type="text" required value={email}  onChange={emailHandler} />
                                      <label>Email address</label>
                                  </div>
                                  {
                                    errorMsg.map((item,index) =>(
                                        <div style={{
                                            color:"red",
                                            fontWeight:"600",
                                            fontSize:"14px",
                                            marginTop:"3px"
                                        }} key={index}>{item.email}</div>
                                    ))
                                  }
                              </div>
                              <div>
                                  <div>
                                      <input type={passwordType} required value={password} onChange={passHandler} />
                                      <label>Password</label>
                                  </div>
                                    <div className={styles.hidePassword}>
                                        {
                                            passwordType === "password" ? <i onClick={showHide} className="fa-solid fa-eye-slash"></i> 
                                            :
                                            <i onClick={showHide}  className="fa-solid fa-eye"></i>
                                        }
                                            {
                                        errorMsg.map((item,index) =>(
                                        <div style={{
                                            color:"red",
                                            fontWeight:"600",
                                            fontSize:"14px",
                                            marginTop:"3px"
                                                }} key={index}>{item.pass}</div>
                                            ))
                                        }
                                    </div>
                              </div>
                              <div className={styles.forgotPassword}>
                                  <span>
                                      <Link to='/forgot_password'>Forgot password</Link>
                                  </span>
                              </div>
                          </div>
                      </div>
                      <div className={styles.loginBtn}>
                              <button type='submit' onClick={loginHandler} >Login</button>
                      </div>
                      <div className={styles.notHaveAcc}>
                          <span>Don't have an account ? <Link to='/register'>Register</Link></span>
                      </div>
                  </div>
                </form>
            </div>
          </div>
        </div>
      </div>
      {
            isLoading && <Loader />
            
        }
      </>
  )
}

export default Login