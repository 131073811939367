import React, { useState } from 'react'
import styles from "./ForgotPassword.module.css"
import NavBar from "../Partialpages/NavBar"
import Footer from '../Partialpages/Footer'
import {forgot} from "../../Validations/Authentication"
import { BASE_URL, SECRET_KEY } from '../../Constants'
import axios from 'axios'
import Loader from '../Utilities/Loader'
import { toast } from 'react-toastify';
import { db } from "../../firebase/config";
import {
    collection,
    addDoc,
    where,
    query,
    getDocs,
  } from "firebase/firestore";
import { random_string } from '../Utilities/CommonFunctions'
import { SERVER_URL,RESET_PASSWORD_EMAIL_TEMPLATE} from '../../Constants'

function ForgotPassword() {

    const forgot_password_colRef = collection(db, "forgot_passwords");
    const merchant_colRef = collection(db, "merchants");

    const [email,setEmail] = useState("")
    const [errorMsg,setErrorMsg] = useState([]) 
    const [isLoading,setIsLoading] = useState(false)
    const emailHandler = (e) =>{
        setEmail(e.target.value)
    }

    const forgotHandler = (e) =>{
        e.preventDefault()
        const data = {
            email:email
        }
        forgot.validate(data,{abortEarly:false})
        .then(async (response) =>{
            setIsLoading(true)
            try{
                const find_email = query(merchant_colRef,where("MerchantEmailAddress", "==", email));
                const exist = await getDocs(find_email)
                if(exist.size > 0){
                    let userData;
                    exist.forEach((doc) => {
                        userData = doc.data()
                        userData.id = doc.id
                    });
                    const date = new Date();
                    date.setMinutes(date.getMinutes() + 15);
                    const random = random_string(10);

                    const doc = await addDoc(forgot_password_colRef,{
                        MerchantId : userData.id,
                        ValidTill : date,
                        ResetCode : random,
                        Status : 'Pending'
                    })

                    if(doc.id){
                        let config = {
                            method: "post",
                            maxBodyLength: Infinity,
                            url: `https://tifinhub-api-hacsigy3hq-uc.a.run.app/foodflyer_email_trigger/send_email`,
                            headers: {
                              "Content-Type": "application/json",
                            },
                            data: {
                              senderEmail: email,
                              subject: "Verify Email",
                              templateKey: RESET_PASSWORD_EMAIL_TEMPLATE,
                              variables: {
                                brand: "Scriptbox",
                                store_name : userData.AccountName,
                                name : `${userData.FirstName} ${userData.LastName}`,
                                password_reset_link: `${SERVER_URL}/update_forgot_password/${random}`,
                              },
                            },
                          };
          
                          const send_link = await axios(config);

                          if (send_link.data.code) {
                            setIsLoading(false)
                            setEmail("")
                            toast.success('Reset link is sent to your email address.')
                          } else {
                            setIsLoading(false)
                            toast.error('Something went wrong while sending the reset email.')
                          }
                    }else{
                        setIsLoading(false)
                        toast.error('Something went wrong.')
                    }
                }else{
                    setIsLoading(false)
                    toast.error('Account not exist with this email.')
                }
            }catch(err) {
                setIsLoading(false)
                console.log(err)
            }
            setErrorMsg([])
        }).catch((error) =>{
            setErrorMsg(error.errors)
        })
    }

    return (
        <>
            <NavBar />
            <div className={styles.container}>
                <div className={styles.wrapper}>
                    <form>
                        <div className={styles.formContainer}>
                            <div className={styles.resetPass}>
                                <h1>Reset password</h1>
                                <p>Enter the email address associated with your account to reset your password.</p>
                            </div>
                            <div className={styles.inputReset}>
                                <div className={styles.inputResetField}>
                                    <div>
                                        <input type="text"  required value={email} onChange={emailHandler} />
                                        <label>Email address</label>
                                    </div>
                                    {
                                    errorMsg.map((item,index) =>(
                                        <div style={{
                                            color:"red",
                                            fontWeight:"600",
                                            fontSize:"14px",
                                            marginTop:"3px"
                                        }} key={index}>{item.email}</div>
                                    ))
                                  }
                                </div>
                            </div>
                            <div className={styles.resetBtn}>
                                <button type='submit' onClick={forgotHandler} >Continue</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <Footer />
            {
                isLoading && <Loader />
            }
        </>
      )
}

export default ForgotPassword