import { initializeApp } from "firebase/app";
import { getFirestore} from "firebase/firestore"

const firebaseConfig = {
  apiKey: "AIzaSyCSTM-NK-gvidAyZgx_IpxpcZvdNBho5Y4",
  authDomain: "scriptbox-f337d.firebaseapp.com",
  projectId: "scriptbox-f337d",
  storageBucket: "scriptbox-f337d.appspot.com",
  messagingSenderId: "561582215623",
  appId: "1:561582215623:web:f94ee3381c7dda6c4789e2",
  measurementId: "G-6P8REBL4V1"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app)


