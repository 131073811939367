import React from 'react'
import Footer from '../Partialpages/Footer'
import NavBar from '../Partialpages/NavBar'
import { useNavigate } from 'react-router-dom'
import styles from "./AccApprove.module.css"

function QrCodeExpire() {
    const navigate = useNavigate()
    const backHandler = () =>{
      navigate("/")
    }
  
    return (
      <>  
        <NavBar />
            <div className={styles.appContainer}>
              <div className={styles.appWrapper}>
                <div className={styles.appLeft}>
                  <div className={styles.gifImg}>
                      <img src="/images/food_anim.gif" alt="gif" />
                  </div>
                </div>
                <div className={styles.appRight}>
                    <div className={styles.message}>
                      <h2>This QR Code is expired.</h2>
                    </div>
                    <div className={styles.btnPart}>
                      <button onClick={backHandler}><i className="fa-solid fa-arrow-left-long"></i> Back to Home</button>
                    </div>
                  </div>
              </div>
            </div>
        <Footer />
      </>
    )
}

export default QrCodeExpire