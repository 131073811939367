import React from 'react'
import style from "./Footer.module.css"
import {Link} from "react-router-dom"

function Footer() {
  return (
    <div className={style.footContainer}>
        <span className={style.privacyMsg}>We’ll never share your details. See our <Link to="/merchant_policy">Privacy Policy</Link></span>
        <span className={style.copyRight}><b>©</b> {new Date().getFullYear()} All Rights are Reserved by Foodflyer </span>
    </div>
  )
}

export default Footer