import React from 'react'
import styles from "./CustomerNav.module.css"


function CustomerNav() {
  return (
    <nav className={styles.nav}>
    <div className={styles.rightSec}>
        <h1>Scriptbox</h1>
    </div>
  </nav>
  )
}

export default CustomerNav