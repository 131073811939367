import React, { useEffect, useState } from 'react'
import styles from "./Preference.module.css"
import CustomerNav from "../Partialpages/CustomerNav"
import Footer from "../Partialpages/Footer"
import { BASE_URL, SECRET_KEY } from '../../Constants'
import axios from 'axios'
import { useParams } from 'react-router-dom'
import Loader from "../../Components/Utilities/Loader"
import { toast } from 'react-toastify';

function Preference() {

    const [isLoading,setIsLoading] = useState(false)
    const [myData,setMyData] = useState([])
    const [checked,setChecked] = useState([])
    const {id} = useParams()

    const fetchPreference = () =>{
        setIsLoading(true)
        const url = BASE_URL + "get_subscriber_merchants"
        const config = {
            headers:{
                "secret_key" : SECRET_KEY,
                "Content-Type" : "application/json"
            }
        }
        const data = {
            id:id
        }
        axios.post(url,data,config)
        .then((response)=>{
            const result = JSON.parse(response.data)
            console.log(result.data)
            if(result.code === 1){
                setIsLoading(false)
                setMyData(result.data)
                var arr = []
                for(let item of result.data){
                    arr.push(item.active ? true : false)
                }
                setChecked(arr)
            }else{
                setIsLoading(false)
            }
        }).catch((error) =>{
            console.log(error)
        })

    }

    useEffect(()=>{
        fetchPreference()
    },[])


    const changePreference = (status,rowId) =>{
        setIsLoading(true)
        const data = {
            id:rowId,
            active:status
        }
        console.log(data)
        const url = BASE_URL + "update_subscriber_merchants_active" 

        const config = {
            headers:{
                "secret_key" : SECRET_KEY,
                "Content-Type" : "application/json"
            }
        }

        axios.post(url,data,config)
        .then((response) =>{
            const result = JSON.parse(response.data)
            if(result.code === 1){
                fetchPreference()
                toast.success("Updating Preferences Successfully")
            }else{
                toast.success("Problem in Updating Preferences")
            }
        })
    }

    const onchangeHandle = (val,pos,id) =>{
        const upDate = checked.map((item,index) =>(
            pos === index ? val : item

        ))
        setChecked(upDate)

        changePreference(val,id)
    }

  return (
    <>
        <CustomerNav />
        <div className={styles.container}>
            <div className={styles.content}> 
                <h1>Manage your Preference</h1>
                <div className={styles.parentRow}>
                    {
                        myData.length !== 0 ? <> 
                        {
                            myData.map((item,index) => (
                                <div className={styles.dataRow} key={index}>
                                    <div className={styles.leftPart}>
                                        <div className={styles.title}>
                                            <p>{item.MerchantName}</p>
                                            <p>{item.BusinessType}</p>
                                        </div>
                                    </div>
                                    <div className={styles.switchButton}>
                                        <input className={styles.switchButtonCheckbox} type="checkbox" onChange={(e) =>{onchangeHandle(e.target.checked,index,item.rowId)}} checked={checked[index]} ></input>
                                        <label className={`${styles.switchButtonLabel} ${checked[index] === true && styles.switchButtonLabelBg}`} >
                                            <span className={styles.switchButtonLabelSpan}>Unsubscribe</span>
                                        </label>
                                    </div>
                                </div>
                            ))
                        }
                        
                        </>

                        :
                        <>
                          <div style={{
                                width:"100%",
                                height:"inherit",
                                display:"flex",
                                flexDirection:"column",
                                gap:"1rem",
                                justifyContent:"center",
                                alignItems:"center"
                            }}>
                                <h1 style={{
                                    width:"100%",
                                    textAlign:"center",
                                }}>Loading...</h1>
                            </div>
                        </>

                    }
                </div>
            </div>
        </div>
        <Footer />
        {
          isLoading && <Loader />
            
        }
    </>
  )
}

export default Preference