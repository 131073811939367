import React from 'react'
import Footer from '../Partialpages/Footer'
import styles from "./AccApprove.module.css"
import {useNavigate} from "react-router-dom"
import NavBar from '../Partialpages/NavBar.js'

function AccApprove() {
  const navigate = useNavigate()
  const backHandler = () =>{
    navigate("/")
  }

  return (
    <>  
      <NavBar />
          <div className={styles.appContainer}>
            <div className={styles.appWrapper}>
              <div className={styles.appLeft}>
                <div className={styles.gifImg}>
                    <img src="/images/food_anim.gif" alt="gif" />
                </div>
              </div>
              <div className={styles.appRight}>
                  <div className={styles.message}>
                    <h2>We are still working on your account.<br></br> Your account is not approved yet.</h2>
                    <h2>Please try to login after some time.</h2>
                  </div>
                  <div className={styles.btnPart}>
                    <button onClick={backHandler}><i className="fa-solid fa-arrow-left-long"></i> Back to Login</button>
                  </div>
                </div>
            </div>
          </div>
      <Footer />
    </>
  )
}

export default AccApprove