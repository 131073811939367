import React,{useState} from 'react'
import Password from './Password'
import SocialMedia from './SocialMedia'
import styles from "./Settings.module.css"
import NavBar from "../../Partialpages/NavBar"
import Footer from "../../Partialpages/Footer"


function Settings() {

  const [tab, setTab] = useState(0)
  const tabControl = (val) =>{
      setTab(val)
  }

  return (
    <>
        <NavBar />
        <div className={styles.section}>
            <div className={styles.rightPart}>
                <div className={styles.wrapper}>
                    <div className={styles.buttons}>
                        <button className={`${styles.btn} ${tab === 0 && styles.btnBefore}`} onClick={() => tabControl(0)}>Password Update</button>
                        <button  className={`${styles.btn} ${tab === 1 && styles.btnBefore}`}  onClick={() => tabControl(1)}>Social Media Update</button>
                        
                    </div>
                    <div className={styles.tabCont}>
                        <div className={styles.tabs}>
                        {
                            tab === 0 && <Password />
                        }
                        {
                            tab === 1 && <SocialMedia />
                        }
                    </div>
                </div>
            </div>
        </div>
        </div>
        <Footer />
    </>
  
  )
}

export default Settings