import React, { useState } from 'react'
import styles from "./VerifyDiscount.module.css"
import { dis_code } from '../../../Validations/HomeValidations'
import Loader from '../../Utilities/Loader'
import { toast } from 'react-toastify';
import { collection,where,query, getDocs,doc, updateDoc } from 'firebase/firestore'
import { db } from '../../../firebase/config'
import { formatDate, todays_date } from '../../Utilities/CommonFunctions'

function VerifyDiscount() {
  const promoCodesColRef = collection(db,"promo_codes")

  const [couponDetails,setCouponDetails] = useState({})
  const [claimNow,setClaimNow] = useState(false)
  const [errorMsg,setErrorMsg] = useState([]) 
  const [code,setCode] = useState("")
  const [isLoading,setIsLoading] = useState(false)

  const checkoutHandler = (e) =>{
    e.preventDefault()

    const data = {
      dis_code:code
    }

    dis_code.validate(data,{abortEarly:false})
    .then(async (response) =>{
      setIsLoading(true)
      try{
        const get_promo_code = query(promoCodesColRef,where('CouponCode','==',data.dis_code))
        const code = await getDocs(get_promo_code)
        if(code.size > 0){
          let codeData;
          code.forEach(doc => {
            codeData = doc.data()
            codeData.id = doc.id
          })
          const d1 = new Date()
          const d2 = new Date(codeData.Expiry + ' 23:59:59')
          if(d1 > d2 ){
            toast.error("Your Coupon is Expired")
          }else{
            if(codeData.IsClaimed){
              toast.error("You are already Claimed")
            }else{
              setCouponDetails(codeData)
              setClaimNow(true)
            }
          }
          setIsLoading(false)
        }else{
          toast.error("Invalid promo code.")
          setIsLoading(false)
        }
      }catch(err){
        setIsLoading(false)
        console.log(err);
      }
      setErrorMsg([])
    }).catch((error) =>{
      setErrorMsg(error.errors)
    })
  }

  const codeHandler = (e) =>{
    setCode(e.target.value)
  }

  const redeemHandler = async () =>{
    setIsLoading(true)

    try{  
      const docRef = doc(db,"promo_codes",couponDetails.id)
      await updateDoc(docRef,{
        IsClaimed:true,
        ClaimedDate: todays_date()
      })
      setClaimNow(false)
      setIsLoading(false)
      setCode("")
      toast.success('Promocode redeem successfully.')
    }catch(err){
      console.log(err);
      setIsLoading(false)
    }
  }

  const closeHandler = () =>{
    setClaimNow(false)
  }

  return (
    <> 
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.wrapper}>
            <h1>Verify Discount</h1>
            <div className={styles.inputType}>
              <div>
                <input type="text" required={true} value={code} onChange={codeHandler} />
                <label>Promo Code</label>
              </div>
              {
                  errorMsg.map((item,index) =>(
                      <div style={{
                          color:"red",
                          fontWeight:"600",
                          fontSize:"14px",
                          marginTop:"3px"
                      }} key={index}>{item.code}</div>
                  ))
                  }
            </div>
            <button onClick={checkoutHandler}>Check</button>
          </div>
        </div>
      </div>
      {
          claimNow && 
          <>
            <div className={styles.popupContainer}>
              <div className={styles.popupWrapper}>
                  <div className={styles.popUpMsg}>
                      <h2>Coupon Details</h2>
                  </div>
                  <div className={styles.details}>
                    <div className={styles.tile}>
                      <div className={styles.key}>
                          <h3>Coupon Code </h3>
                      </div>
                      <div className={styles.value}>
                          {couponDetails.CouponCode}
                      </div>
                    </div>
                    <div className={styles.tile}>
                      <div className={styles.key}>
                          <h3>Coupon Value </h3>
                      </div>
                      <div className={styles.value}>
                          {couponDetails.CouponValue}
                      </div>
                    </div>
                    <div className={styles.tile}>
                      <div className={styles.key}>
                          <h3>Coupon Type </h3>
                      </div>
                      <div className={styles.value}>
                          {couponDetails.PromocodeType}
                      </div>
                    </div>
                    <div className={styles.tile}>
                      <div className={styles.key}>
                          <h3>Generate Date </h3>
                      </div>
                      <div className={styles.value}>
                          {formatDate(new Date(couponDetails.CreatedDate.seconds * 1000 + couponDetails.CreatedDate.nanoseconds / 1e6))}
                      </div>
                    </div>
                    <div className={styles.tile}>
                      <div className={styles.key}>
                          <h3>Expire Date </h3>
                      </div>
                      <div className={styles.value}>
                          {couponDetails.Expiry}
                      </div>
                    </div>
                  </div>
                  <div className={styles.popupBtns}>
                    <button onClick={redeemHandler} >Redeem Now</button>
                  </div>
                  <div className={styles.close}> 
                      <i onClick={closeHandler} className="fa-sharp fa-solid fa-xmark"></i>
                  </div>
              </div>
            </div>
          </>
        }
        {
            isLoading && <Loader />
            
        }
    </>
  )
}

export default VerifyDiscount