import React, { useState } from "react";
import styles from "./Register.module.css";
import { Link, useNavigate } from "react-router-dom";
import Autocomplete from "react-google-autocomplete";
import axios from "axios";
import Loader from "../Utilities/Loader";
import { register } from "../../Validations/Authentication";
import { SERVER_URL, VERIFY_EMAIL_TEMPLATE } from "../../Constants/index";
import { toast } from "react-toastify";
import { db } from "../../firebase/config";
import {
  collection,
  addDoc,
  where,
  query,
  getCountFromServer,
} from "firebase/firestore";
import { Base64 } from "../../EncodeDecode/index";

function Register() {
  const colRef = collection(db, "merchants");

  const [errorMsg, setErrorMsg] = useState([]);
  const navigate = useNavigate();
  const [add, setAdd] = useState(false);
  const [bT, setBT] = useState(false);
  const [TC, setTC] = useState(false);
  const [allData, setAllData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    store_name: "",
    password: "",
    buisness_type: "Business Type",
    phone: "",
  });
  const [addr, setAddr] = useState({
    city: "",
    country: "",
    postal_code: "",
  });

  const [isLoading, setIsLoading] = useState(false);
  const [address, setAddress] = useState("");
  const [passwordType, setPasswordType] = useState("password");
  const showHide = () => {
    if (passwordType === "password") {
      setPasswordType("text");
    } else {
      setPasswordType("password");
    }
  };

  const changeHandler = (e) => {
    setAllData({
      ...allData,
      [e.target.name]: e.target.value,
    });
  };

  const signUpHandler = (e) => {
    e.preventDefault();

    const data = {
      ...allData,
      store_address: address,
    };
    address === "" ? setAdd(true) : setAdd(false);
    allData.buisness_type === "Business Type" ? setBT(true) : setBT(false);

    register
      .validate(data, { abortEarly: false })
      .then(async (response) => {
        if (address === "" || allData.buisness_type === "Business Type") {
          console.log("wrong");
        } else {
          if (!TC) {
            alert("Please Accept Terms and Conditions");
          } else {
            setIsLoading(true);
            try {
              const find_email = query(
                colRef,
                where("MerchantEmailAddress", "==", allData.email)
              );
              const found = await getCountFromServer(find_email);
              if (!found.data().count) {
                const doc = await addDoc(colRef, {
                  AccountName: allData.store_name,
                  MerchantName: allData.store_name,
                  FirstName: allData.first_name,
                  LastName: allData.last_name,
                  EmailVerified: false,
                  AccountApproved: false,
                  HasDataAccess: false,
                  MerchantEmailAddress: allData.email,
                  BusinessType: allData.buisness_type,
                  MerchantPassword: Base64.encode(allData.password),
                  Facebook: "",
                  Instagram: "",
                  Twitter: "",
                  Tiktok: "",
                  MerchantPhone: allData.phone,
                  SICCode: "",
                  LastActivityDate: new Date(),
                  MerchantProvince: "",
                  MerchantAddress: address,
                  MerchantCity: addr.city,
                  MerchantCountry: addr.country,
                  MerchantPostalCode: addr.postal_code,
                });
                let config = {
                  method: "post",
                  maxBodyLength: Infinity,
                  url: `https://tifinhub-api-hacsigy3hq-uc.a.run.app/foodflyer_email_trigger/send_email`,
                  headers: {
                    "Content-Type": "application/json",
                  },
                  data: {
                    senderEmail: allData.email,
                    subject: "Verify Email",
                    templateKey: VERIFY_EMAIL_TEMPLATE,
                    variables: {
                      brand: "Scriptbox",
                      full_name: `${allData.first_name} ${allData.last_name}`,
                      verify_url: `${SERVER_URL}/verify_email/${doc.id}`,
                    },
                  },
                };

                const register = await axios(config);

                if (register.data.code) {
                  setIsLoading(false);
                  navigate(
                    `/?msg=${"Account registered successfully. Please verify your email"}&flag=success`
                  );
                } else {
                  toast.error("Problem in sending email.");
                }
              } else {
                setIsLoading(false);
                toast.error(
                  "Email address already registered. Please login to continue."
                );
              }
            } catch (err) {
              console.log(err);
              setIsLoading(false);
            }
          }
        }
        setErrorMsg([]);
      })
      .catch((error) => {
        setErrorMsg(error.errors);
      });
  };

  const tcHandler = (e) => {
    setTC(e.target.checked);
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <div className={styles.right}>
            <div className={styles.loginForm}>
              <form>
                <div className={styles.formContainer}>
                  <div className={styles.welcome}>
                    <h1>Sign Up</h1>
                    <p>Let's work together to grow your business</p>
                  </div>
                  <div className={styles.inputContainer}>
                    <div className={styles.inputField}>
                      <div>
                        <div>
                          <input
                            className={styles.input}
                            type='text'
                            required
                            name='store_name'
                            value={allData.store_name}
                            onChange={changeHandler}
                          />
                          <label>Store Name</label>
                        </div>
                        {errorMsg.map((item, index) => (
                          <div
                            style={{
                              color: "red",
                              fontWeight: "600",
                              fontSize: "14px",
                              marginTop: "3px",
                            }}
                            key={index}>
                            {item.store_name}
                          </div>
                        ))}
                      </div>
                      <div>
                        <div>
                          <Autocomplete
                            apiKey='AIzaSyDsewz1qgdIcG21YdoAgIlLQwY546IxdCU'
                            style={{ width: "100%" }}
                            required={true}
                            className={styles.address}
                            onPlaceSelected={(place) => {
                              let postalCode;
                              let city;
                              let country;
                              for (
                                let i = 0;
                                i < place?.address_components.length;
                                i++
                              ) {
                                for (
                                  let j = 0;
                                  j < place?.address_components[i].types.length;
                                  j++
                                ) {
                                  if (
                                    place?.address_components[i].types[j] ==
                                    "postal_code"
                                  ) {
                                    postalCode =
                                      place.address_components[i].long_name;
                                  }
                                  if (
                                    place?.address_components[i].types[j] ==
                                    "locality"
                                  ) {
                                    city =
                                      place.address_components[i].long_name;
                                  }
                                  if (
                                    place?.address_components[i].types[j] ==
                                    "country"
                                  ) {
                                    country =
                                      place.address_components[i].long_name;
                                  }
                                }
                              }
                              setAddress(place.formatted_address);
                              setAddr({
                                city: city,
                                postal_code: postalCode,
                                country: country,
                              });
                            }}
                            options={{
                              componentRestrictions: { country: ["ca"] },
                              types: ["geocode", "establishment"],
                            }}
                            placeholder={""}
                          />
                          <label>Store address</label>
                        </div>
                        {add && (
                          <div
                            style={{
                              color: "red",
                              fontWeight: "600",
                              fontSize: "14px",
                              marginTop: "3px",
                            }}>
                            **This field is required
                          </div>
                        )}
                      </div>
                      <div>
                        <div>
                          <input
                            className={styles.input}
                            type='text'
                            required
                            name='first_name'
                            value={allData.first_name}
                            onChange={changeHandler}
                          />
                          <label>First Name</label>
                        </div>
                        {errorMsg.map((item, index) => (
                          <div
                            style={{
                              color: "red",
                              fontWeight: "600",
                              fontSize: "14px",
                              marginTop: "3px",
                            }}
                            key={index}>
                            {item.fname}
                          </div>
                        ))}
                      </div>
                      <div>
                        <div>
                          <input
                            className={styles.input}
                            type='text'
                            required
                            name='last_name'
                            value={allData.last_name}
                            onChange={changeHandler}
                          />
                          <label>Last Name</label>
                        </div>
                        {errorMsg.map((item, index) => (
                          <div
                            style={{
                              color: "red",
                              fontWeight: "600",
                              fontSize: "14px",
                              marginTop: "3px",
                            }}
                            key={index}>
                            {item.lname}
                          </div>
                        ))}
                      </div>
                      <div>
                        <div>
                          <input
                            className={styles.input}
                            type='text'
                            required
                            name='email'
                            value={allData.email}
                            onChange={changeHandler}
                          />
                          <label>Email address</label>
                        </div>
                        {errorMsg.map((item, index) => (
                          <div
                            style={{
                              color: "red",
                              fontWeight: "600",
                              fontSize: "14px",
                              marginTop: "3px",
                            }}
                            key={index}>
                            {item.email}
                          </div>
                        ))}
                      </div>
                      <div>
                        <div>
                          <input
                            className={styles.input}
                            type={passwordType}
                            required
                            name='password'
                            value={allData.password}
                            onChange={changeHandler}
                          />
                          <label>Password</label>
                        </div>

                        <div className={styles.hidePassword}>
                          {passwordType === "password" ? (
                            <i
                              onClick={showHide}
                              className='fa-solid fa-eye-slash'></i>
                          ) : (
                            <i
                              onClick={showHide}
                              className='fa-solid fa-eye'></i>
                          )}
                        </div>
                        {errorMsg.map((item, index) => (
                          <div
                            style={{
                              color: "red",
                              fontWeight: "600",
                              fontSize: "14px",
                              marginTop: "3px",
                            }}
                            key={index}>
                            {item.pass}
                          </div>
                        ))}
                      </div>
                      <div>
                        <div>
                          <input
                            className={styles.input}
                            type='tel'
                            required
                            name='phone'
                            value={allData.phone}
                            onChange={changeHandler}
                          />
                          <label>Phone Number</label>
                        </div>
                        {errorMsg.map((item, index) => (
                          <div
                            style={{
                              color: "red",
                              fontWeight: "600",
                              fontSize: "14px",
                              marginTop: "3px",
                            }}
                            key={index}>
                            {item.phone}
                          </div>
                        ))}
                      </div>
                      <div>
                        <div>
                          <select
                            value={allData.buisness_type}
                            name='buisness_type'
                            onChange={changeHandler}>
                            <option disabled>Business Type</option>
                            <option value='Pizza Store'>Pizza Store</option>
                            <option value='Restaurant'>Restaurant</option>
                            <option value='Convenience Store'>
                              Convenience Store
                            </option>
                            <option value='Grocery Store'>Grocery Store</option>
                            <option value='Specialty Store'>
                              Specialty Store
                            </option>
                            <option value='Liquor Store'>Liquor Store</option>
                            <option value='Florist'>Florist</option>
                            <option value='Retail'>Retail</option>
                            <option value='Pharmacy'>Pharmacy</option>
                            <option value='Cannabis Store'>
                              Cannabis Store
                            </option>
                          </select>
                        </div>
                        {bT && (
                          <div
                            style={{
                              color: "red",
                              fontWeight: "600",
                              fontSize: "14px",
                              marginTop: "3px",
                            }}>
                            **Please Select one option
                          </div>
                        )}
                      </div>
                      <div className={styles.question}>
                        <input
                          id='agree'
                          type='checkbox'
                          className={styles.agree}
                          onChange={tcHandler}
                          name='appointment'
                        />
                        <label style={{ fontSize: "16px" }} htmlFor='agree'>
                          {" "}
                          I agree with the{" "}
                          <Link to='/merchant_policy'>
                            {" "}
                            Terms and Conditions{" "}
                          </Link>{" "}
                        </label>
                      </div>
                      <div className={styles.registerPart}>
                        <div className={styles.signUpBtn}>
                          <button onClick={signUpHandler} type='submit'>
                            Register
                          </button>
                        </div>
                        <div className={styles.haveAcc}>
                          <span>
                            Already have an account ? <Link to='/'>Login</Link>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className={styles.left}>
            <div className={styles.leftWrapper}>
              <div className={styles.gifImg}>
                <img src='/images/food_anim.gif' alt='gif' />
              </div>
              <div className={styles.message}>
                <h1>Start saving</h1>
                <h1>your time & money</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isLoading && <Loader />}
    </>
  );
}

export default Register;
